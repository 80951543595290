import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, notification, PaginationProps, Table, Typography } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { AxiosError } from 'axios';
import type { ColumnsType } from 'antd/es/table';
import { useQuery } from 'react-query';
import qs from 'qs';

import CoExistenceService from '../../../services/coExistance';
import styles from './styles.module.scss';
import { getQueryParams } from '../../../utils/getQueryParams';

const { Text } = Typography;
interface DataType {
  key: React.Key;
  name: string;
}

type Props = {};

export const StockTable = ({}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [tableData, setTableData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [totalInfo, setTotalInfo] = useState<Record<string, string | number>>({});

  const { data, isFetching, error } = useQuery(['stockTable', location.search, page], ({ signal }) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
    delete params?.page;
    return CoExistenceService.getStock(getQueryParams(params), { signal });
  });

  const onPageChange: PaginationProps['onChange'] = (page) => {
    setPage(page);
  };

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.info({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  useEffect(() => {
    if (data) {
      setTableData(data?.data?.results);
      setTotal(data?.data?.count);
      setTotalInfo(data?.data?.additional[0]);
    }
    if (error instanceof AxiosError) {
      if (error?.response?.status === 400) {
        openNotification('Fill required fields');
        setTableData([]);
      } else if (error?.response?.status === 403) {
        openNotification('You do not have permission to perform this action.');
        setTableData([]);
      } else {
        openNotification('Data with your request not found');
        setTableData([]);
      }
    }

    return () => {
      setTableData([]);
    };
  }, [data, error, location.search]);

  const columns = useMemo<ColumnsType<DataType>>(
    () => [
      {
        title: 'Number of Products',
        dataIndex: 'quantity',
        width: 1,
        key: 'quantity',
        align: 'center',
        render: (value: any) => {
          return (
            <Button
              type="link"
              onClick={() =>
                navigate({
                  pathname: `/co-existence/combination/stock/${value}`,
                  search: `${location.search}`,
                })
              }
            >
              {value}
            </Button>
          );
        },
      },
      {
        title: 'Co-existence stores',
        dataIndex: 'stores',
        width: 1,
        key: 'stores',
        align: 'center',
      },
      {
        title: 'Co-existence share',
        dataIndex: 'share',
        width: 1,
        key: 'share',
        align: 'center',
        render: (value: any) => {
          return `${value} %`;
        },
      },
    ],
    []
  );

  return (
    <div style={{ width: isTabletOrMobile ? '100%' : '48%' }}>
      Stock (Real time data)
      <Table
        columns={columns}
        dataSource={tableData}
        loading={isFetching}
        bordered
        size="small"
        scroll={{ x: 200 }}
        pagination={{
          pageSize: 10,
          defaultPageSize: 10,
          total: total,
          onChange: onPageChange,
          current: page,
          defaultCurrent: 1,
        }}
        summary={() => {
          return (
            <>
              <Table.Summary.Row>
                {totalInfo && (
                  <>
                    <Table.Summary.Cell index={0} align="center">
                      <Text className={styles.total_bold}>{totalInfo?.quantity}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="center">
                      <Text className={styles.total_bold}>{totalInfo?.stores}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align="center">
                      <Text className={styles.total_bold}>{totalInfo?.share}</Text>
                    </Table.Summary.Cell>
                  </>
                )}
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
};
