import { Collapse, Select } from 'antd';
import { isTobacco } from '../../../utils/isTobacco';

const { Panel } = Collapse;

export const useColumns = ({ isTabletOrMobile, setOrdering, ordering, onSelectOption, option }): any => {
  const defaultStyles: React.CSSProperties = {
    margin: 0,
    padding: 0,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: isTabletOrMobile ? '11px' : '14px',
    display: 'flex',
  };

  const options = isTobacco()
    ? [
        {
          value: 'value',
          label: 'Value',
        },
        {
          value: 'item',
          label: 'Item',
        },
      ]
    : [
        {
          value: 'value',
          label: 'Value',
        },
        {
          value: 'item',
          label: 'Item',
        },
        {
          value: 'volume',
          label: 'Volume',
        },
      ];
  return [
    {
      title: 'Store ID',
      render: (value: any) => {
        return (
          <>
            <Collapse ghost>
              <Panel header={`${value?.hashed_store_id}`} key="1">
                {value?.city && (
                  <div style={{ color: '#A9A9A9', fontWeight: 400, ...defaultStyles }}>
                    Город: &nbsp;<p style={defaultStyles}>{value?.city}</p>
                  </div>
                )}
                {value?.store_name && (
                  <div style={{ color: '#A9A9A9', fontWeight: 400, ...defaultStyles }}>
                    Название: &nbsp;<p style={defaultStyles}>{value?.store_name}</p>
                  </div>
                )}
                {value?.address && (
                  <div style={{ color: '#A9A9A9', fontWeight: 400, ...defaultStyles }}>
                    Адрес: &nbsp;<p style={defaultStyles}>{value?.address}</p>
                  </div>
                )}
              </Panel>
            </Collapse>
          </>
        );
      },
    },
    {
      title: () => (
        <Select
          defaultValue="value"
          onSelect={onSelectOption}
          style={{ width: '96px' }}
          onClick={(e) => e.stopPropagation()}
          variant="borderless"
          options={options}
        />
      ),
      align: 'center',
      render: (value: any) => {
        return (
          <p style={{ ...defaultStyles, justifyContent: 'center' }}>
            {`${value?.[option]}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}
          </p>
        );
      },
      sorter: true,
      sortOrder: ordering === 'value' || ordering === '-value' ? (ordering === 'value' ? 'ascend' : 'descend') : null,
      onHeaderCell: () => {
        return {
          onClick: () => {
            const newOrdering = ordering === 'value' ? '-value' : 'value';
            setOrdering(newOrdering);
          },
        };
      },
    },
    {
      title: 'Share',
      align: 'center',
      render: (value: any) => {
        return <p style={{ ...defaultStyles, justifyContent: 'center' }}>{value?.[`${option}_share`] || '--'} %</p>;
      },
      sorter: true,
      sortOrder:
        ordering === 'my_share' || ordering === '-my_share' ? (ordering === 'my_share' ? 'ascend' : 'descend') : null,
      onHeaderCell: () => {
        return {
          onClick: () => {
            const newOrdering = ordering === 'my_share' ? '-my_share' : 'my_share';
            setOrdering(newOrdering);
          },
        };
      },
    },
  ];
};
