export const regionCoordinates = [
  {
    name: 'Akmola region',
    x: '57%',
    y: '30%',
    textAnchor: 'middle',
    fill: '#eeeeee',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '500',
    dy: '.3em',
  },
  {
    name: 'Astana city',
    x: '63%',
    y: '35%',
    textAnchor: 'middle',
    fill: '#FFF',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: '500',
    dy: '.3em',
  },
  {
    name: 'Aktobe region',
    x: '32%',
    y: '55%',
    textAnchor: 'middle',
    fill: '#eeeeee',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '500',
    dy: '.3em',
  },
  {
    name: 'Almaty region',
    x: '71%',
    y: '80%',
    textAnchor: 'middle',
    fill: '#eeeeee',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
    dy: '.3em',
  },
  {
    name: 'Almaty city',
    x: '72%',
    y: '91%',
    textAnchor: 'middle',
    fill: '#000000',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: '500',
    dy: '.3em',
  },
  {
    name: 'Atyrau region',
    x: '18%',
    y: '60%',
    textAnchor: 'middle',
    fill: '#eeeeee',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '500',
    dy: '.3em',
  },
  {
    name: 'Abai region',
    x: '79%',
    y: '53%',
    textAnchor: 'middle',
    fill: '#eeeeee',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '500',
    dy: '.3em',
  },
  {
    name: 'Zhambyl region',
    x: '62%',
    y: '80%',
    textAnchor: 'middle',
    fill: '#eeeeee',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '500',
    dy: '.3em',
  },
  {
    name: 'Ulytau region',
    x: '52%',
    y: '60%',
    textAnchor: 'middle',
    fill: '#eeeeee',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '500',
    dy: '.3em',
  },
  {
    name: 'Kostanay region',
    x: '42%',
    y: '32%',
    textAnchor: 'middle',
    fill: '#eeeeee',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '500',
    dy: '.3em',
  },
  {
    name: 'Kyzylorda region',
    x: '42%',
    y: '74%',
    textAnchor: 'middle',
    fill: '#eeeeee',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '500',
    dy: '.3em',
  },
  {
    name: 'Mangystau region',
    x: '21%',
    y: '82%',
    textAnchor: 'middle',
    fill: '#eeeeee',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '500',
    dy: '.3em',
  },
  {
    name: 'North Kazakhstan region',
    x: '54%',
    y: '13%',
    textAnchor: 'middle',
    fill: '#eeeeee',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '500',
    dy: '.3em',
  },
  {
    name: 'Pavlodar region',
    x: '71%',
    y: '29%',
    textAnchor: 'middle',
    fill: '#eeeeee',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '500',
    dy: '.3em',
  },
  {
    name: 'Turkestan region',
    x: '54%',
    y: '90%',
    textAnchor: 'middle',
    fill: '#eeeeee',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
    dy: '.3em',
  },
  {
    name: 'Shymkent city',
    x: '60%',
    y: '97%',
    textAnchor: 'middle',
    fill: '#000000',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: '500',
    dy: '.3em',
  },
  {
    name: 'West Kazakhstan region',
    x: '14%',
    y: '47%',
    textAnchor: 'middle',
    fill: '#eeeeee',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '500',
    dy: '.3em',
  },
  {
    name: 'Karagandy region',
    x: '66%',
    y: '53%',
    textAnchor: 'middle',
    fill: '#eeeeee',
    fontSize: '24px',
    fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, sans-serif",
    fontStyle: 'normal',
    fontWeight: '500',
    dy: '.3em',
  },
  {
    name: 'Jetisu region',
    x: '76%',
    y: '72%',
    textAnchor: 'middle',
    fill: '#eeeeee',
    fontSize: '24px',
    fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, sans-serif",
    fontStyle: 'normal',
    fontWeight: '500',
    dy: '.3em',
  },
  {
    name: 'East Kazakhstan region',
    x: '90%',
    y: '51%',
    textAnchor: 'middle',
    fill: '#eeeeee',
    fontSize: '24px',
    fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, sans-serif",
    fontStyle: 'normal',
    fontWeight: '500',
    dy: '.3em',
  },
];
