import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import qs from 'qs';
import { Empty, Input, notification, Spin } from 'antd';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataset,
} from 'chart.js';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { format } from 'date-fns';

import { dateRange } from '../../../utils/lastThreeMonths';
import BuyingBehaviorService from '../../../services/buyingBehavior';
import { debounce } from '../../../utils/debounce';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

type Props = {
  rule?: any;
  isTabletOrMobile?: boolean;
};

export const ChartVolume = ({ rule, isTabletOrMobile }: Props) => {
  const options = {
    responsive: true,
    radius: 0,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'Volume',
        font: {
          size: 18,
        },
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        titleFont: {
          size: 14,
        },
        bodyFont: {
          size: 14,
        },
        callbacks: {
          title: (context: any) => {
            return `${context[0]?.raw}`;
          },
          label: (ctx: any) => {
            const label = `Volume: ${ctx?.label}`;
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: '',
          font: {
            size: 16,
          },
        },
        ticks: {
          font: {
            size: 14,
          },
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Items',
          font: {
            size: 16,
          },
        },
        ticks: {
          font: {
            size: 14,
          },
        },
      },
    },
  };

  const location = useLocation();
  const [step, setStep] = useState<number>(0.25);
  const defaultEndDate = format(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM');
  const defaultStartDate = format(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM');
  const [labels, setLabels] = useState<string[]>(dateRange(defaultStartDate, defaultEndDate));
  const regions = rule?.regions?.join(',');
  const { data, error, isFetching } = useQuery(['volume', step, location.search], ({ signal }) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
    return BuyingBehaviorService.getReportsVolume(
      {
        months: params?.months || dateRange(defaultStartDate, defaultEndDate).join(','),
        city: params?.city || regions,
        local_company: params?.local_company_id,
        subcategory: params?.subcategory_id,
        step: step,
        ...params,
      },
      { signal }
    );
  });

  // const getData = useCallback(() => {
  //   queryClient.refetchQueries('trends', { active: true });
  // }, [queryClient]);

  const setDebounceState = debounce(setStep, 2000);
  const debounceChange = (event) => {
    setDebounceState(Number(event.target.value));
  };

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.info({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  useEffect(() => {
    if (data) {
      setLabels(data.data['abscissa']);
    }

    if (error instanceof AxiosError) {
      if (error?.response?.status === 403) {
        openNotification('You do not have permission to perform this action.');
      } else {
        openNotification('Data with your request not found');
      }
    }
  }, [data, error]);

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Volume',
        borderWidth: 2,
        data: data?.data?.['ordinate'],
        backgroundColor: 'rgb(54, 162, 235)',
        borderColor: 'rgb(54, 162, 235)',
        fill: 'start',
        tension: 0.3,
      },
    ] as ChartDataset[],
  };

  return (
    <div style={{ width: isTabletOrMobile ? '100%' : '48%' }}>
      <span style={{ marginTop: '24px' }}>
        <label>Volume step: </label>
        <Input defaultValue="0.25" onChange={debounceChange} type="number" style={{ width: '100px' }} />
      </span>
      {isFetching ? (
        <div style={{ height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Spin />
        </div>
      ) : data?.data?.['ordinate']?.length ? (
        <div>
          <Chart
            type="line"
            data={chartData}
            options={{ ...options, aspectRatio: isTabletOrMobile ? 1 : undefined }}
            height={120}
          />
        </div>
      ) : (
        <Empty />
      )}
    </div>
  );
};
