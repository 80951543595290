import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Drawer } from 'antd';
import { FilterOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import qs from 'qs';
import { format } from 'date-fns';
import { useMediaQuery } from 'react-responsive';

import { CustomFilter } from '../../components/filter/CustomFilter';
// import FilterService from '../../services/filter';
import CoExistenceService from '../../services/coExistance';
// import { downloadFile } from '../../utils/downloadFile';
// import { FilterChips, Tag } from '../../components/filter-chips/filterChips';
import { CoExistanceTrendsTable } from './components/CoExistanceTrendsTable';
import styles from './styles.module.scss';
import { DownloadsDrawer } from '../../components/DownloadsDrawer/DownloadsDrawer';
import { openNotification } from '../../utils/lastThreeMonths';
import { getQueryParams } from '../../utils/getQueryParams';
// import { InformationModal } from './components/InformationModal';

// const ExportMenuItems = (onDownload: (type: 'stock' | 'sale') => void) => (
//   <Menu>
//     {/* <Menu.Item key="sale">
//       <div onClick={() => onDownload('sale')}>Sale</div>
//     </Menu.Item> */}
//     <Menu.Item key="stock">
//       <div onClick={() => onDownload('stock')}>Stock</div>
//     </Menu.Item>
//   </Menu>
// );

export const CoExistenceTrendsPage = () => {
  const location = useLocation();
  // const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [openFilter, setOpenFilter] = useState(false);
  // const [tags, setTags] = useState<Tag[]>([]);
  // const [openModal, setOpenModal] = useState(false);
  // const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [isOpenDownloads, setIsOpenDonwloads] = useState<boolean>(false);
  const defaultDate = format(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM')
    .split('-')
    .join('');

  // const onChipClose = (tag: Tag) => {
  //   queryParams.delete(tag.filter);
  //   navigate({ pathname: location.pathname, search: queryParams.toString() });
  // };

  // const handleCloseModal = () => {
  //   setOpenModal(false);
  // };

  const onOpenFilter = () => {
    setOpenFilter(true);
  };

  const onFilterChange = () => {
    setOpenFilter(false);
  };

  const hide = () => {
    setOpenFilter(false);
  };

  const closeDownloads = () => {
    setIsOpenDonwloads(false);
  };

  const handleDownloadFile = () => {
    setExportLoading(true);
    CoExistenceService.exportCoExistanceTrends(getQueryParams(params, defaultDate))
      .then((res) => {
        setIsOpenDonwloads(true);
        openNotification('File added to downloads folder', 'success');
        // downloadFile(res.data, `report_${`trends`}_${params?.months || defaultDate}` || 'coexistence_trends_report');
      })
      .finally(() => setExportLoading(false));
  };

  // useEffect(() => {
  //   let arr: Tag[] = [];
  //   Object.keys(params).map((item) => {
  //     if (item !== 'page' && item !== 'limit') {
  //       switch (item) {
  //         case 'city':
  //           FilterService.getNewOptions(item, params[item]).then((res) => {
  //             setTags((prevState) => [
  //               ...prevState,
  //               { filter: item, name: params[item], label: res.data.results?.map((val: any) => val?.title)?.join(',') },
  //             ]);
  //           });
  //           break;
  //         case 'type_of_package':
  //         case 'brand':
  //         case 'local_company':
  //         case 'subcategory':
  //           FilterService.getNewOptions(item, params[item]).then((res) => {
  //             setTags((prevState) => [
  //               ...prevState,
  //               { filter: item, name: params[item], label: res.data.results?.map((val: any) => val[item])?.join(',') },
  //             ]);
  //           });
  //           break;
  //         default:
  //           arr.push({
  //             filter: item,
  //             name: params[item],
  //           });
  //       }
  //     }
  //     return arr;
  //   });
  //   setTags(arr);
  //   return () => {
  //     setTags([]);
  //   };
  // }, [location.search]);

  return (
    <div className={styles.company_layout_content}>
      <div className={styles.top_bar}>
        <div className={styles.wrapper}>
          {isTabletOrMobile && (
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', overflowX: 'auto' }}>
              <div>
                <Drawer
                  title=""
                  placement="left"
                  onClose={hide}
                  open={openFilter}
                  width={isTabletOrMobile ? '320' : undefined}
                >
                  <CustomFilter pageName="co-existence" type="brand" onFilterChange={onFilterChange} onCancel={hide} />
                </Drawer>
                <Button
                  type="default"
                  className={styles.company_filter_btn}
                  onClick={onOpenFilter}
                  icon={<FilterOutlined />}
                />
              </div>
              {/* <div style={{ marginLeft: '15px' }}>{tags && <FilterChips tags={tags || []} onClose={onChipClose} />}</div> */}
            </div>
          )}
          {!isTabletOrMobile && (
            <>
              <div>
                <Drawer title="" placement="left" onClose={hide} open={openFilter}>
                  <CustomFilter pageName="co-existence" type="brand" onFilterChange={onFilterChange} onCancel={hide} />
                </Drawer>
                <Button
                  type="default"
                  className={styles.company_filter_btn}
                  onClick={onOpenFilter}
                  icon={<FilterOutlined />}
                >
                  &nbsp;&nbsp;Filter
                </Button>
              </div>
              {/* <div style={{ marginLeft: '15px' }}>
                {tags && <FilterChips tags={tags || []} onClose={onChipClose} />}
              </div> */}
            </>
          )}
        </div>
        &nbsp;&nbsp;&nbsp;
        <Button type="default" className={styles.export_btn} onClick={handleDownloadFile} loading={exportLoading}>
          <VerticalAlignBottomOutlined />
          Export
        </Button>
      </div>
      <div style={{ width: '100%' }}>
        <CoExistanceTrendsTable />
      </div>
      <Drawer title="Downloads" width="auto" placement="right" onClose={closeDownloads} open={isOpenDownloads}>
        <DownloadsDrawer open={isOpenDownloads} />
      </Drawer>
    </div>
  );
};
