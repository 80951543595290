// import { FC } from 'react';
// import { Route } from 'react-router-dom';

// import { ProtectedLayout } from '../components/protectedLayout/protectedLayout';
// import { Profile } from '../models';
import { AbcAnalysisPage } from '../pages/abcAnalysis/AbcAnalysisPage';
// import { BasketAnalysisPage } from '../pages/basketAnalysis/BasketAnalysisPage';
import { BuyingBehaviorPage } from '../pages/buyingBehavior/BuyingBehaviorPage';
import { CoExistencePage } from '../pages/coExistence/CoExistencePage';
import { CoExistenceTrendsPage } from '../pages/coExistenceTrends/CoExistenceTrendsPage';
import { ComparisonPage } from '../pages/comparison/ComparisonPage';
import { ComparisonProductPage } from '../pages/comparisonProduct/ComparisonProductPage';
import { ComparisonSharePage } from '../pages/comparisonShare/ComparisonSharePage';
import { MarketSharePage } from '../pages/marketShare/MarketSharePage';

import { OutOfStockPage } from '../pages/outOfStock/OutOfStockPage';
// import { CreateRole } from '../pages/permissions/CreateRole/CreateRole';
// import { CreateUser } from '../pages/permissions/CreateUser/CreateUser';
import { PermissionsPage } from '../pages/permissions/PermissionsPage';
import { PriceElasticityPage } from '../pages/priceElasticity/PriceElasticityPage';
import { SalesStatisticsPage } from '../pages/salesStatistics/SalesStatisticsPage';
import { TrendsPage } from '../pages/trends/TrendsPage';
import { TrendsSharePage } from '../pages/trendsShare/TrendsSharePage';
import { DetailedLogsPage } from '../pages/userLogs/DetailedLogsPage';
import { UserLogsPage } from '../pages/userLogs/UserLogsPage';
import { VpoSegmentationPage } from '../pages/vpoSegmentation/VpoSegmentationPage';
import { VpoTrendsPage } from '../pages/vpoTrends/VpoTrendsPage';
import { StoresPage } from '../pages/stores/StoresPage';
import { StoreStatisticsPage } from '../pages/storeStatistics/StoreStatisticsPage';
import { CoExistenceCombinationsPage } from '../pages/coExistenceCombinations/CoExistenceCombinationsPage';
import { DownloadsPage } from '../pages/downloads/DownloadsPage';

import { SalesStatisticsReportsPage } from '../pages/salesStatisticsReports/SalesStatisticsReportsPage';
import { ComparisonShareReportsPage } from '../pages/comparisonShareReports/ComparisonSharePage';
import { MarketSharePage as MarketShareReportsPage } from '../pages/marketShareReports/MarketSharePage';
import { TrendsSharePage as TrendsShareReportsPage } from '../pages/trendsShareReports/TrendsSharePage';
import { TrendsReportsPage } from '../pages/trendsReports/TrendsReportsPage';
import { ComparisonReportsPage } from '../pages/comparisonReports/ComparisonReportsPage';
import { DashboardPage } from '../components/home/DashboardPage';

export const NAVIGATION_ROUTES = [
  {
    path: '/main',
    element: <DashboardPage />,
  },
  {
    path: '/dashboard',
    element: <DashboardPage />,
  },
  {
    path: '/sales/:type',
    element: <SalesStatisticsReportsPage />,
  },
  {
    path: '/trends/:type/:tab',
    element: <TrendsReportsPage />,
  },
  {
    path: '/comparison/:type/:tab',
    element: <ComparisonReportsPage />,
  },
  {
    path: '/permissions/:type',
    element: <PermissionsPage />,
  },
  {
    path: '/permissions/users/create',
    element: <PermissionsPage />,
  },
  {
    path: '/permissions/users/:userId/edit',
    element: <PermissionsPage />,
  },
  {
    path: '/permissions/roles/create',
    element: <PermissionsPage />,
  },
  {
    path: '/permissions/roles/:roleId/edit',
    element: <PermissionsPage />,
  },
  {
    path: '/users-logs',
    element: <UserLogsPage />,
  },
  {
    path: '/users-logs/:userId',
    element: <DetailedLogsPage />,
  },
  {
    path: '/abc-analysis/:tab',
    element: <AbcAnalysisPage />,
  },
  {
    path: '/market-share/:type',
    element: <MarketShareReportsPage />,
  },
  {
    path: '/trends-share/:type',
    element: <TrendsShareReportsPage />,
  },
  {
    path: '/out-of-stock',
    element: <OutOfStockPage />,
  },
  {
    path: '/buying-behavior',
    element: <BuyingBehaviorPage />,
  },
  {
    path: '/price-elasticity',
    element: <PriceElasticityPage />,
  },
  {
    path: '/comparison-share/:type',
    element: <ComparisonShareReportsPage />,
  },
  {
    path: '/comparison-product',
    element: <ComparisonProductPage />,
  },
  {
    path: '/co-existence',
    element: <CoExistencePage />,
  },
  {
    path: '/co-existence/combination/:type/:number_of_products',
    element: <CoExistenceCombinationsPage />,
  },
  {
    path: '/co-existence-trends',
    element: <CoExistenceTrendsPage />,
  },
  {
    path: '/vpo-segmentation',
    element: <VpoSegmentationPage />,
  },
  {
    path: '/vpo-trends',
    element: <VpoTrendsPage />,
  },
  {
    path: '/stores',
    element: <StoresPage />,
  },
  {
    path: '/stores-statistics/:type/:typeOfPage',
    element: <StoreStatisticsPage />,
  },
  {
    path: '/downloads',
    element: <DownloadsPage />,
  },
  {
    path: '/sales/reports/:type',
    element: <SalesStatisticsPage />,
  },
  {
    path: '/trends/reports/:type/:tab',
    element: <TrendsPage />,
  },
  {
    path: '/reports/comparison/:type/:tab',
    element: <ComparisonPage />,
  },
  {
    path: '/market-share/reports/:type',
    element: <MarketSharePage />,
  },
  {
    path: '/trends-share/reports/:type',
    element: <TrendsSharePage />,
  },
  {
    path: '/comparison-share/reports/:type',
    element: <ComparisonSharePage />,
  },
];
