import { AxiosResponse } from 'axios';
import { reportsApiClient } from './interceptor';
import { PageQueryParams } from '../models';

interface QueryParams extends PageQueryParams {
  search?: string;
  ids?: string[];
  subcategory?: string[];
}

export const ReportFilterService = () => {
  const getLocalCompanies = (params?: QueryParams): Promise<AxiosResponse> => {
    return reportsApiClient.get(`/api/v1/local_company/`, { params });
  };

  const getCategories = (params?: QueryParams): Promise<AxiosResponse> => {
    return reportsApiClient.get(`/api/v1/category/`, { params });
  };

  const getSubcategories = (params?: QueryParams): Promise<AxiosResponse> => {
    return reportsApiClient.get(`/api/v1/subcategory/`, { params });
  };

  const getBrands = (params?: QueryParams): Promise<AxiosResponse> => {
    return reportsApiClient.get(`/api/v1/brand/`, { params });
  };

  const getProducts = (params?: QueryParams): Promise<AxiosResponse> => {
    return reportsApiClient.get(`/api/v1/product/`, { params });
  };

  const getCountries = (params?: QueryParams): Promise<AxiosResponse> => {
    return reportsApiClient.get(`/api/v1/country/`, { params });
  };

  const getRegions = (params?: QueryParams): Promise<AxiosResponse> => {
    return reportsApiClient.get(`/api/v1/region/`, { params });
  };

  const getRegionsExtrapolatable = (params?: QueryParams): Promise<AxiosResponse> => {
    return reportsApiClient.get(`/api/v1/region/extrapolatable/`, { params });
  };

  const getStores = (params?: QueryParams): Promise<AxiosResponse> => {
    return reportsApiClient.get(`/api/v1/store/`, { params });
  };

  const getAvailableFilters = (subcategory?: string): Promise<AxiosResponse> => {
    const params = { subcategory };
    return reportsApiClient.get(`/api/v1/category_characteristics/?size=100`, { params });
  };

  const getBatteryCapacity = (params?: QueryParams): Promise<AxiosResponse> => {
    return reportsApiClient.get(`/api/v1/battery_capacity/`, { params });
  };

  const getConcentration = (params: QueryParams) => {
    return reportsApiClient.get(`/api/v1/concentration/`, { params });
  };

  const getFlavor = (params: QueryParams) => {
    return reportsApiClient.get(`/api/v1/flavor/`, { params });
  };

  const getLiquidVolume = (params: QueryParams) => {
    return reportsApiClient.get(`/api/v1/liquid_volume/`, { params });
  };

  const getPuff = (params: QueryParams) => {
    return reportsApiClient.get(`/api/v1/puff/`, { params });
  };

  const getSaltNicotine = (params: QueryParams) => {
    return reportsApiClient.get(`/api/v1/salt_nicotine/`, { params });
  };

  const getTypeOfPackage = (params: QueryParams) => {
    return reportsApiClient.get(`/api/v1/type_of_package/`, { params });
  };

  const getSizeOfPackage = (params: QueryParams) => {
    return reportsApiClient.get(`/api/v1/size_of_package/`, { params });
  };

  const getSizeOfPackageGrouped = (params: QueryParams) => {
    return reportsApiClient.get(`/api/v1/size_of_package/grouped/`, { params });
  };

  const getNewOptions = (type: string, ids?: string, ordering?: string): Promise<AxiosResponse> => {
    const params = {
      ids,
      ordering,
    };
    return reportsApiClient.get(`/api/v1/${type}/`, { params });
  };

  return {
    getLocalCompanies,
    getCategories,
    getSubcategories,
    getBrands,
    getProducts,
    getCountries,
    getRegions,
    getRegionsExtrapolatable,
    getStores,
    getAvailableFilters,
    getBatteryCapacity,
    getConcentration,
    getFlavor,
    getLiquidVolume,
    getPuff,
    getSaltNicotine,
    getTypeOfPackage,
    getSizeOfPackage,
    getSizeOfPackageGrouped,
    getNewOptions,
  };
};
