export const getQueryParams = (params: Record<string, any>, defaultDate?: string, regions?: any) => {
  const { months, size, limit, ...rest } = params;

  return {
    months: months || defaultDate,
    limit: +limit || 10,
    city: params?.city_id || regions,
    subcategory: params?.subcategory_id,
    local_company: params?.local_company_id,
    brand: params?.brand_id,
    common_stores: params.active_stores,
    ...rest,
  };
};

export const getMeasureQueryParams = (
  measure: string,
  params: Record<string, any>,
  defaultDate?: string,
  regions?: any
) => {
  const { months, size, limit, ...rest } = params;

  return {
    measure: measure,
    months: months || defaultDate,
    limit: +limit || 10,
    city: params?.city_id || regions,
    subcategory: params?.subcategory_id,
    local_company: params?.local_company_id,
    brand: params?.brand_id,
    common_stores: params.active_stores,
    ...rest,
  };
};
