import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import qs from 'qs';
import { Empty, notification, Select } from 'antd';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataset,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { format } from 'date-fns';

import TrendsShareService from '../../../services/trendsShare';
import { Loader } from '../../../components/loader/Loader';
import { dateRange } from '../../../utils/lastThreeMonths';
import { backgroundColors } from '../../../utils/randomColor';
import { useMediaQuery } from 'react-responsive';
import { getQueryParams } from '../../../utils/getQueryParams';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ChartDataLabels);

type Props = {
  pageName: string;
  rule?: any;
};

export const TrendsShareChart = ({ pageName, rule }: Props) => {
  const options: any = {
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      datalabels: {
        backgroundColor: function (context) {
          return context.dataset.backgroundColor;
        },
        borderRadius: 4,
        color: 'white',
        font: {
          weight: 'bold',
        },
        formatter: Math.round,
        padding: 6,
      },
      tooltip: {
        callbacks: {
          title: (context: any) => {
            return `${context[0]?.label}`;
          },
        },
      },
      title: {
        display: true,
        text: '',
      },
    },
  };
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [items, setItems] = useState<ChartDataset[]>();
  const [itemsShare, setItemsShare] = useState<ChartDataset[]>();
  const [value, setValue] = useState<ChartDataset[]>();
  const [valueShare, setValueShare] = useState<ChartDataset[]>();
  const [volume, setVolume] = useState<ChartDataset[]>();
  const [volumeShare, setVolumeShare] = useState<ChartDataset[]>();
  const [avgPrice, setAvgPrice] = useState<ChartDataset[]>();
  const [numericDistr, setNumericDistr] = useState<ChartDataset[]>();
  const [weightedDistr, setWeightedDistr] = useState<ChartDataset[]>();
  const defaultEndDate = format(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM');
  const defaultStartDate = format(new Date().setMonth(new Date().getMonth() - 6), 'yyyy-MM');
  const [labels, setLabels] = useState<string[]>(dateRange(defaultStartDate, defaultEndDate));
  const [cities, setCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState<string[]>(['Total']);
  const regions = rule?.regions?.join(',');

  const { data, error, isFetching } = useQuery(['trends', pageName, location.search], ({ signal }) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
    setLabels(params?.months?.split(',') || dateRange(defaultStartDate, defaultEndDate));
    switch (pageName) {
      case 'company':
        return TrendsShareService.getChartTrendsShareByCompany(
          getQueryParams(params, dateRange(defaultStartDate, defaultEndDate).join(','), regions),
          { signal }
        );
      case 'brand':
        return TrendsShareService.getChartTrendsShareByBrand(
          getQueryParams(params, dateRange(defaultStartDate, defaultEndDate).join(','), regions),
          { signal }
        );
      case 'barcode':
        return TrendsShareService.getChartTrendsShareByBarcode(
          getQueryParams(params, dateRange(defaultStartDate, defaultEndDate).join(','), regions),
          { signal }
        );
      default:
        return;
    }
  });

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.info({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  useEffect(() => {
    if (data) {
      const arr = [];
      Object.keys(data?.data)?.map((item, index) => {
        arr.push({ label: item, value: item });
      });
      const value = selectedCities.map((item, index) => {
        return {
          label: item,
          data: (data.data?.[item]?.['value'] as number[]) || [],
          borderColor: backgroundColors[index],
          backgroundColor: backgroundColors[index],
          lineTension: 0.3,
        };
      });

      const valueShare = selectedCities.map((item, index) => {
        return {
          label: item,
          data: data.data?.[item]?.['value_share'] as number[],
          borderColor: backgroundColors[index],
          backgroundColor: backgroundColors[index],
          lineTension: 0.3,
        };
      });

      const volume = selectedCities.map((item, index) => {
        return {
          label: item,
          data: data.data?.[item]?.['volume'] as number[],
          borderColor: backgroundColors[index],
          backgroundColor: backgroundColors[index],
          lineTension: 0.3,
        };
      });

      const volumeShare = selectedCities.map((item, index) => {
        return {
          label: item,
          data: data.data?.[item]?.['volume_share'] as number[],
          borderColor: backgroundColors[index],
          backgroundColor: backgroundColors[index],
          lineTension: 0.3,
        };
      });

      const avgPrice = selectedCities.map((item, index) => {
        return {
          label: item,
          data: data.data?.[item]?.['avg_price'] as number[],
          borderColor: backgroundColors[index],
          backgroundColor: backgroundColors[index],
          lineTension: 0.3,
        };
      });

      const numericDistr = selectedCities.map((item, index) => {
        return {
          label: item,
          data: data.data?.[item]?.['numeric_distribution'] as number[],
          borderColor: backgroundColors[index],
          backgroundColor: backgroundColors[index],
          lineTension: 0.3,
        };
      });

      const weightedDistr = selectedCities.map((item, index) => {
        return {
          label: item,
          data: data.data?.[item]?.['weighted_distribution'] as number[],
          borderColor: backgroundColors[index],
          backgroundColor: backgroundColors[index],
          lineTension: 0.3,
        };
      });

      const items = selectedCities.map((item, index) => {
        return {
          label: item,
          data: data.data?.[item]?.['items'] as number[],
          borderColor: backgroundColors[index],
          backgroundColor: backgroundColors[index],
          lineTension: 0.3,
        };
      });

      const itemsShare = selectedCities.map((item, index) => {
        return {
          label: item,
          data: data.data?.[item]?.['items_share'] as number[],
          borderColor: backgroundColors[index],
          backgroundColor: backgroundColors[index],
          lineTension: 0.3,
        };
      });

      setCities(arr);
      setValue(value);
      setValueShare(valueShare);
      setVolume(volume);
      setVolumeShare(volumeShare);
      setAvgPrice(avgPrice);
      setNumericDistr(numericDistr);
      setWeightedDistr(weightedDistr);
      setItems(items);
      setItemsShare(itemsShare);
    }

    if (error instanceof AxiosError) {
      if (error?.response?.status === 400) {
        if (error?.response?.data?.['local_company']) {
          openNotification(`Local company: ${error?.response?.data?.['local_company']?.[0]}`);
        } else {
          openNotification(error?.response?.data?.['months']?.[0]);
        }
        setValueShare([]);
      }
      if (error?.response?.status === 403) {
        openNotification('You do not have permission to perform this action.');
        setValueShare([]);
      } else {
        openNotification('Data with your request not found');
        setValueShare([]);
      }
    }

    return () => {
      setCities([]);
      setValue([]);
      setValueShare([]);
      setVolume([]);
      setVolumeShare([]);
      setAvgPrice([]);
      setNumericDistr([]);
      setWeightedDistr([]);
      setItems([]);
      setItemsShare([]);
    };
  }, [data, selectedCities, error]);

  const chartDataValue = {
    labels,
    datasets: value as ChartDataset[],
  };

  const chartDataValueShare = {
    labels,
    datasets: valueShare as ChartDataset[],
  };

  const chartDataVolume = {
    labels,
    datasets: volume as ChartDataset[],
  };

  const chartDataVolumeShare = {
    labels,
    datasets: volumeShare as ChartDataset[],
  };

  const chartDataAvgPrice = {
    labels,
    datasets: avgPrice as ChartDataset[],
  };

  const chartDataNumericDistr = {
    labels,
    datasets: numericDistr as ChartDataset[],
  };

  const chartDataWeightedDistr = {
    labels,
    datasets: weightedDistr as ChartDataset[],
  };

  const chartDataItems = {
    labels,
    datasets: items as ChartDataset[],
  };

  const chartDataItemsShare = {
    labels,
    datasets: itemsShare as ChartDataset[],
  };

  return (
    <>
      {isFetching ? (
        <Loader />
      ) : data ? (
        <>
          <div style={{ marginTop: '24px' }}>
            <label>Select city: </label>
            <Select
              options={cities}
              defaultValue={['Total']}
              onChange={(value) => {
                setSelectedCities(value);
              }}
              style={{ minWidth: 144 }}
              maxTagCount="responsive"
              mode="multiple"
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: isTabletOrMobile ? 'column' : 'row',
              justifyContent: 'space-between',
              width: '100%',
              flexWrap: 'wrap',
              textAlign: 'center',
              fontWeight: 600,
            }}
          >
            {value && (
              <div style={{ width: isTabletOrMobile ? '100%' : '48%' }}>
                <label>Value</label>
                <Chart
                  type="line"
                  data={chartDataValue}
                  options={{ ...options, aspectRatio: isTabletOrMobile ? 1 : undefined }}
                  height={100}
                />
              </div>
            )}

            {valueShare && (
              <div style={{ width: isTabletOrMobile ? '100%' : '48%' }}>
                <label>Value share</label>
                <Chart
                  type="line"
                  data={chartDataValueShare}
                  options={{ ...options, aspectRatio: isTabletOrMobile ? 1 : undefined }}
                  height={100}
                />
              </div>
            )}

            {volume && (
              <div style={{ width: isTabletOrMobile ? '100%' : '48%' }}>
                <label>Volume</label>
                <Chart
                  type="line"
                  data={chartDataVolume}
                  options={{ ...options, aspectRatio: isTabletOrMobile ? 1 : undefined }}
                  height={100}
                />
              </div>
            )}

            {volumeShare && (
              <div style={{ width: isTabletOrMobile ? '100%' : '48%' }}>
                <label>Volume share</label>
                <Chart
                  type="line"
                  data={chartDataVolumeShare}
                  options={{ ...options, aspectRatio: isTabletOrMobile ? 1 : undefined }}
                  height={100}
                />
              </div>
            )}

            {avgPrice && (
              <div style={{ width: isTabletOrMobile ? '100%' : '48%' }}>
                <label>Average price</label>
                <Chart
                  type="line"
                  data={chartDataAvgPrice}
                  options={{ ...options, aspectRatio: isTabletOrMobile ? 1 : undefined }}
                  height={100}
                />
              </div>
            )}

            {numericDistr && (
              <div style={{ width: isTabletOrMobile ? '100%' : '48%' }}>
                <label>Numeric distribution</label>
                <Chart
                  type="line"
                  data={chartDataNumericDistr}
                  options={{ ...options, aspectRatio: isTabletOrMobile ? 1 : undefined }}
                  height={100}
                />
              </div>
            )}

            {weightedDistr && (
              <div style={{ width: isTabletOrMobile ? '100%' : '48%' }}>
                <label>Weighted distribution</label>
                <Chart
                  type="line"
                  data={chartDataWeightedDistr}
                  options={{ ...options, aspectRatio: isTabletOrMobile ? 1 : undefined }}
                  height={100}
                />
              </div>
            )}

            {items && (
              <div style={{ width: isTabletOrMobile ? '100%' : '48%' }}>
                <label>Items</label>
                <Chart
                  type="line"
                  data={chartDataItems}
                  options={{ ...options, aspectRatio: isTabletOrMobile ? 1 : undefined }}
                  height={100}
                />
              </div>
            )}

            {itemsShare && (
              <div style={{ width: isTabletOrMobile ? '100%' : '48%' }}>
                <label>Items share</label>
                <Chart
                  type="line"
                  data={chartDataItemsShare}
                  options={{ ...options, aspectRatio: isTabletOrMobile ? 1 : undefined }}
                  height={100}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <Empty />
      )}
    </>
  );
};
