import { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { Button, DatePicker, Drawer, Dropdown, Form, Menu, Modal, RadioChangeEvent, notification } from 'antd';
import { FilterOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import qs from 'qs';
import { format } from 'date-fns';
import { useMediaQuery } from 'react-responsive';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { CustomFilter } from '../../components/filter/CustomFilter';
import { SalesStatisticsReportsTable } from './components/SalesStatisticsTable';
import SalesStatisticsService from '../../services/salesStatistics';
import { ToggleMenuItems } from '../../components/toggleMenuItems/ToggleMenuitems';
import { RadioGroupMenu } from '../../components/RadioGroupMenu/RadioGroupMenu';
// import { downloadFile } from '../../utils/downloadFile';
import { dateRange, openNotification } from '../../utils/lastThreeMonths';
import { isTobacco } from '../../utils/isTobacco';
import { DownloadsDrawer } from '../../components/DownloadsDrawer/DownloadsDrawer';
import { downloadFile } from '../../utils/downloadFile';
import styles from './styles.module.scss';

const CustomDatePicker = DatePicker.generatePicker<Date>(dateFnsGenerateConfig);
const { RangePicker } = CustomDatePicker;
dayjs.extend(customParseFormat);

export const SalesStatisticsReportsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const { type } = useParams<{ type: string; tab: string }>();
  const [openFilter, setOpenFilter] = useState(false);
  const [isExtrapolated, setIsExtrapolated] = useState<boolean>(false);
  const [page, setPage] = useState<string>('company');
  const [isOpenDownloads, setIsOpenDonwloads] = useState<boolean>(false);
  const [isOpenDetailedModal, setIsOpenDetailedModal] = useState<boolean>(false);

  const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
  const rules = JSON.parse(localStorage.getItem('user'))?.role;
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const defaultDate = format(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM')
    .split('-')
    .join('');

  const [open, setOpen] = useState(false);
  const [hiddenColumns, setHiddenColumns] = useState({
    value: { title: 'Value kzt', value: false },
    items: { title: 'Items', value: false },
    ...(!isTobacco() && { volume: { title: 'Volume', value: false } }),
    avg_price: { title: 'Avg. price', value: false },
    ...(!isTobacco() && { avg_price_per_litre: { title: 'Avg. price per litre', value: false } }),
    numeric_distribution: { title: 'Numeric dist.', value: false },
    weighted_distribution: { title: 'Weighted dist.', value: false },
    cde: { title: 'CDE', value: false },
    stores: { title: 'Stores', value: false },
  });

  const ExportMenuItems = (onDownload: (type: 'report' | 'detailedReport') => void) => (
    <Menu>
      <Menu.Item key="report">
        <div onClick={() => onDownload('report')}>Report</div>
      </Menu.Item>
      <Menu.Item key="detailedReport">
        <div onClick={() => showDetailedModal()}>Detailed report</div>
      </Menu.Item>
    </Menu>
  );

  const onChangePage = ({ target: { value } }: RadioChangeEvent) => {
    setPage(value);
    navigate({
      pathname: `/sales/${value}`,
      search: `${location.search}`,
    });
  };

  useEffect(() => {
    if (location.pathname.includes('company')) {
      setPage('company');
    } else if (location.pathname.includes('brand')) {
      setPage('brand');
    } else if (location.pathname.includes('barcode')) {
      setPage('barcode');
    }
    return () => {
      setPage('company');
    };
  }, [location.pathname]);

  const onClickExtrapolated = (checked: boolean) => {
    setIsExtrapolated(checked);
  };

  const onOpenFilter = () => {
    setOpenFilter(true);
  };

  const onFilterChange = () => {
    setOpenFilter(false);
  };

  const hide = () => {
    setOpenFilter(false);
  };

  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };

  const showDetailedModal = () => {
    setIsOpenDetailedModal(true);
  };

  const handleToggle = (key) => {
    setHiddenColumns((prevColumns) => ({
      ...prevColumns,
      [key]: { ...prevColumns[key], value: !prevColumns[key].value },
    }));
  };

  const closeDownloads = () => {
    setIsOpenDonwloads(false);
  };

  const handleDownloadFile = () => {
    setExportLoading(true);
    isExtrapolated
      ? SalesStatisticsService.exportExtrapolationReportsByType(type === 'company' ? 'local_company' : type, {
          period_1_after: params?.period_after,
          period_1_before: params?.period_before,
          size: +params?.limit || 10,
          common_stores: params.active_stores,
          ...params,
        })
          .then((res) => {
            downloadFile(res.data, `sales_report_${type}_${params?.months || defaultDate}.xlsx` || 'sales_report');
          })
          .catch((err) => openNotification('Something went wrong', 'error'))
          .finally(() => setExportLoading(false))
      : SalesStatisticsService.exportReportsByType(type === 'company' ? 'local_company' : type, {
          period_1_after: params?.period_after,
          period_1_before: params?.period_before,
          size: +params?.limit || 10,
          common_stores: params.active_stores,
          ...params,
        })
          .then((res) => {
            // if (res.data) {
            //   setIsOpenDonwloads(true);
            //   openNotification('File added to downloads folder', 'success');
            // }
            downloadFile(res.data, `sales_report_${type}_${params?.months || defaultDate}.xlsx` || 'sales_report.xlsx');
          })
          .catch((err) => openNotification('Something went wrong', 'error'))
          .finally(() => setExportLoading(false));
  };

  const handleDownloadDetailedReport = (mode: 'report' | 'detailedReport', months?: string) => {
    if (mode === 'detailedReport') {
      setExportLoading(true);
      SalesStatisticsService.exportReportsScanData({
        months: months,
        city_id: params?.city,
        common_stores: params.active_stores,
        subcategory_id: params?.subcategory,
        local_company_id: params?.local_company,
        brand_id: params.brand,
        ...params,
      })
        .then((res) => {
          // if (res.data) {
          //   setIsOpenDonwloads(true);
          //   openNotification('File added to downloads folder', 'success');
          // }
          downloadFile(res.data, `detailedReport_${type}_${params?.months || defaultDate}.xlsx` || 'detailed_report');
        })
        .catch((err) => openNotification('Something went wrong', 'error'))
        .finally(() => setExportLoading(false));
    } else {
      handleDownloadFile();
    }
  };

  const onFinish = (values: any) => {
    if (values.monthsRange) {
      const months = dateRange(format(values?.monthsRange[0], 'yyyy-MM'), format(values?.monthsRange[1], 'yyyy-MM'));
      delete values.monthsRange;
      values = {
        months: months.join(','),
      };
    }

    handleDownloadDetailedReport('detailedReport', values.months);
  };

  return (
    <div className={styles.company_layout_content}>
      <div className={styles.top_bar}>
        <div className={styles.wrapper}>
          {!isTabletOrMobile && (
            <div className={styles.title}>
              <RadioGroupMenu onChange={onChangePage} value={page} />
            </div>
          )}

          {!isTabletOrMobile && (
            <>
              <div style={{ marginLeft: '15px' }}>
                <Drawer title="" placement="left" onClose={hide} open={openFilter}>
                  <CustomFilter
                    type={type}
                    pageName="reports_sales"
                    onFilterChange={onFilterChange}
                    onCancel={hide}
                    isExtrapolated={isExtrapolated}
                    setExtrapolated={onClickExtrapolated}
                  />
                </Drawer>
                <Button
                  type="default"
                  className={styles.company_filter_btn}
                  onClick={onOpenFilter}
                  icon={<FilterOutlined />}
                >
                  &nbsp;&nbsp;Filter
                </Button>
              </div>
            </>
          )}
          {isTabletOrMobile && (
            <div>
              <Drawer
                title=""
                placement="left"
                onClose={hide}
                open={openFilter}
                width={isTabletOrMobile ? '320' : undefined}
              >
                <CustomFilter
                  type={type}
                  pageName="reports_sales"
                  onFilterChange={onFilterChange}
                  onCancel={hide}
                  isExtrapolated={isExtrapolated}
                  setExtrapolated={onClickExtrapolated}
                />
              </Drawer>
              <Button
                type="default"
                className={styles.company_filter_btn}
                onClick={onOpenFilter}
                icon={<FilterOutlined />}
              />
            </div>
          )}
        </div>
        {!isTabletOrMobile && (
          <Dropdown
            menu={ToggleMenuItems(hiddenColumns, handleToggle)}
            placement="bottom"
            onOpenChange={handleOpenChange}
            open={open}
          >
            <Button>Table settings</Button>
          </Dropdown>
        )}
        &nbsp;&nbsp;&nbsp;
        {type === 'barcode' ? (
          <Dropdown overlay={ExportMenuItems(handleDownloadDetailedReport)}>
            <Button type="default" className={styles.export_btn} loading={exportLoading}>
              <VerticalAlignBottomOutlined />
              Export
            </Button>
          </Dropdown>
        ) : (
          <Button type="default" className={styles.export_btn} onClick={handleDownloadFile} loading={exportLoading}>
            <VerticalAlignBottomOutlined />
            Export
          </Button>
        )}
      </div>

      <SalesStatisticsReportsTable
        isExtrapolation={isExtrapolated}
        pageName={type}
        rule={rules}
        hiddenColumns={hiddenColumns}
        isMobile={isTabletOrMobile}
      />

      <Drawer title="Downloads" width="auto" placement="right" onClose={closeDownloads} open={isOpenDownloads}>
        <DownloadsDrawer open={isOpenDownloads} />
      </Drawer>
      <Modal
        title="Select period"
        open={isOpenDetailedModal}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onFinish(values);
              setIsOpenDetailedModal(false);
            })
            .catch((errorInfo) => {
              console.error('Validation failed:', errorInfo);
            });
        }}
        confirmLoading={exportLoading}
        onCancel={() => setIsOpenDetailedModal(false)}
      >
        <Form form={form} layout="vertical" key="form" onFinish={onFinish}>
          <Form.Item name="monthsRange" noStyle rules={[{ required: true }]}>
            <RangePicker
              format="YYYY-MM"
              picker="month"
              allowClear={true}
              style={{ width: '100%' }}
              className={styles.ant_calendar}
              disabledDate={(current) => {
                return current && dayjs(current).isAfter(dayjs().endOf('month'));
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
