import React, { useEffect, useMemo, useState } from 'react';
import { Drawer, List, notification, Table } from 'antd';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import qs from 'qs';

import PriceElasticityService from '../../../services/priceElasticity';
import { PriceElasticityInfo } from '../../../utils/helperText';
import { useColumns } from './useColumns';
import { Loader } from '../../../components/loader/Loader';
import { PriceElasticityCard } from './Card';
import { getQueryParams } from '../../../utils/getQueryParams';

type Props = {
  pageName?: string;
};

export const PriceElasticityTable = () => {
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [tableData, setTableData] = useState<any[]>([]);
  const [periodLabels, setPeriodLabels] = useState<string[]>([]);
  const [openInfoModal, setOpenInfoModal] = useState(false);

  const { data, isFetching, error } = useQuery(['priceElasticityTable', location.search], ({ signal }) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
    return PriceElasticityService.getPriceElasticityTable(
      {
        period_1_after: params?.period_1_after,
        period_1_before: params?.period_1_before,
        period_2_after: params?.period_2_after,
        period_2_before: params?.period_2_before,
        local_company: params?.local_company_id,
        ...getQueryParams(params),
      },
      { signal }
    );
  });

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.info({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  const onOpenInfoModal = () => {
    setOpenInfoModal(true);
  };

  const closeInfoModal = () => {
    setOpenInfoModal(false);
  };

  useEffect(() => {
    if (data) {
      setTableData(data?.data?.results);
      setPeriodLabels(data?.data?.additional);
    }
    if (error instanceof AxiosError) {
      if (error?.response?.status === 400) {
        openNotification('Fill required fields');
        setTableData([]);
      } else if (error?.response?.status === 403) {
        openNotification('You do not have permission to perform this action.');
        setTableData([]);
      } else {
        openNotification('Data with your request not found');
        setTableData([]);
      }
    }

    return () => {
      setTableData([]);
    };
  }, [data, error, location.search]);

  const columnsParams = useMemo(
    () => ({ tableData, periodLabels, onOpenInfoModal }),
    [tableData, periodLabels, onOpenInfoModal]
  );
  const columns = useColumns(columnsParams);

  return (
    <>
      {!isTabletOrMobile ? (
        <Table
          columns={columns}
          dataSource={tableData}
          loading={isFetching}
          bordered
          size="middle"
          scroll={{ x: 1300 }}
        />
      ) : isFetching ? (
        <Loader />
      ) : (
        tableData && <PriceElasticityCard tableData={tableData} periodLabels={periodLabels} />
      )}
      <Drawer placement="right" onClose={closeInfoModal} open={openInfoModal}>
        <List
          size="small"
          dataSource={PriceElasticityInfo}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta title={item.title} description={item.info} />
            </List.Item>
          )}
        />
      </Drawer>
    </>
  );
};
