import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { notification, PaginationProps, Table } from 'antd';
import { AxiosError } from 'axios';
import type { ColumnsType } from 'antd/es/table';
import { useQuery } from 'react-query';
import qs from 'qs';

import CoExistenceService from '../../../services/coExistance';

interface DataType {
  key: React.Key;
  name: string;
}

type Props = {
  type: 'sale' | 'stock';
  number_of_products: string;
  isActiveTab?: boolean;
};

export const ProductTable = ({ type, number_of_products, isActiveTab }: Props) => {
  const location = useLocation();
  const [tableData, setTableData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState(1);

  const { data, isFetching, error } = useQuery(
    ['productTable', type, isActiveTab, location.search, page],
    ({ signal }) => {
      const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
      delete params?.page;
      switch (type) {
        case 'sale':
          return CoExistenceService.getCoExistenceCombinationSaleProduct(
            {
              number_of_products: number_of_products || params?.number_of_products,
              limit: 10,
              page: page,
              brand: params?.brand_id,
              city: params?.city_id,
              local_company: params?.local_company_id,
              subcategory: params?.subcategory_id,
              ...params,
            },
            { signal }
          );

        case 'stock':
          return CoExistenceService.getCoExistenceCombinationStockProduct(
            {
              number_of_products: number_of_products || params?.number_of_products,
              limit: 10,
              page: page,
              brand: params?.brand_id,
              city: params?.city_id,
              local_company: params?.local_company_id,
              subcategory: params?.subcategory_id,
              ...params,
            },
            { signal }
          );
        default:
          return;
      }
    }
  );

  const onPageChange: PaginationProps['onChange'] = (page) => {
    setPage(page);
  };

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.info({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  useEffect(() => {
    if (data) {
      const dataWithKeys = data?.data?.results.map((item, index) => ({ ...item, key: `${item?.stores}-${index}` }));
      setTableData(dataWithKeys);
      setTotal(data?.data?.count);
    }
    if (error instanceof AxiosError) {
      if (error?.response?.status === 400) {
        openNotification('Fill required fields');
        setTableData([]);
      } else if (error?.response?.status === 403) {
        openNotification('You do not have permission to perform this action.');
        setTableData([]);
      } else {
        openNotification('Data with your request not found');
        setTableData([]);
      }
    }

    return () => {
      setTableData([]);
    };
  }, [data, error, location.search]);

  const columns = useMemo<ColumnsType<DataType>>(
    () => [
      {
        title: 'Stores',
        dataIndex: 'stores',
        width: 1,
        key: 'stores',
        align: 'center',
      },
      {
        title: 'Product',
        dataIndex: 'name_val',
        width: 1,
        key: 'name_val',
        align: 'center',
      },
      {
        title: 'Barcode',
        dataIndex: 'barcode',
        width: 1,
        key: 'barcode',
        align: 'center',
      },
    ],
    []
  );

  return (
    <div style={{ width: '100%' }}>
      <Table
        columns={columns}
        dataSource={tableData}
        loading={isFetching}
        bordered
        size="small"
        scroll={{ x: 200 }}
        pagination={{
          pageSize: 10,
          defaultPageSize: 10,
          total: total,
          onChange: onPageChange,
          current: page,
          defaultCurrent: 1,
        }}
      />
    </div>
  );
};
