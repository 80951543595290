import { AxiosRequestConfig, AxiosResponse } from 'axios';
import fetchClient from './axiosInstance';
import { reportsApiClient } from './interceptor';

interface BuyingBehaviorQueryParams {
  months: string;
  step?: string | number;
  city?: string;
  brand?: string;
  barcode?: string;
  local_company?: string;
  size_of_package?: string;
  subcategory?: string;
  type_of_package?: string;
  active_stores?: boolean;
  validated_stores?: boolean;
}

export default class BuyingBehaviorService {
  static async getPrice(params: BuyingBehaviorQueryParams, customConfig?: AxiosRequestConfig): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/buying_behavior/price/`, { params, ...customConfig });
  }

  static async getReportsPrice(
    params: BuyingBehaviorQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return reportsApiClient.get(`/api/v1/buying_behavior/price/`, { params, ...customConfig });
  }

  static async getVolume(params: BuyingBehaviorQueryParams, customConfig?: AxiosRequestConfig): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/buying_behavior/volume/`, { params, ...customConfig });
  }

  static async getReportsVolume(
    params: BuyingBehaviorQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return reportsApiClient.get(`/api/v1/buying_behavior/volume/`, { params, ...customConfig });
  }

  static async getWeek(params: BuyingBehaviorQueryParams, customConfig?: AxiosRequestConfig): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/buying_behavior/week/`, { params, ...customConfig });
  }

  static async getReportsWeek(
    params: BuyingBehaviorQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return reportsApiClient.get(`/api/v1/buying_behavior/week/`, { params, ...customConfig });
  }

  static async getReportsHour(
    params: BuyingBehaviorQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return reportsApiClient.get(`/api/v1/buying_behavior/hour/`, { params, ...customConfig });
  }
}
