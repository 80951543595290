import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import qs from 'qs';
import { Empty, notification } from 'antd';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataset,
} from 'chart.js';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { format } from 'date-fns';

import { Loader } from '../../../components/loader/Loader';
import { dateRange } from '../../../utils/lastThreeMonths';
import MarketShareService from '../../../services/marketShare';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

type Props = {
  pageName: string;
  rule?: any;
};

export const MarketShareChart = ({ pageName, rule }: Props) => {
  const options = {
    responsive: true,
    interaction: {
      mode: 'index' as const,
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right' as const,
      },
      title: {
        display: true,
        text: '',
      },
      datalabels: {
        display: false,
      },
    },
  };
  const location = useLocation();
  const [trendsData, setTrendsData] = useState<ChartDataset[]>();
  const defaultEndDate = format(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM');
  const defaultStartDate = format(new Date().setMonth(new Date().getMonth() - 6), 'yyyy-MM');
  const [labels, setLabels] = useState<string[]>(['']);
  const regions = rule?.regions?.join(',');

  const { data, error, isFetching } = useQuery(['market-share-chart', pageName, location.search], ({ signal }) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
    switch (pageName) {
      case 'company':
        return MarketShareService.getMarketShareGraphByCompany(
          {
            months: params?.months || dateRange(defaultStartDate, defaultEndDate).join(','),
            city: params?.city || regions,
            ...params,
          },
          { signal }
        );

      case 'brand':
        return MarketShareService.getMarketShareGraphByBrand(
          {
            months: params?.months || dateRange(defaultStartDate, defaultEndDate).join(','),
            city: params?.city || regions,
            ...params,
          },
          { signal }
        );

      case 'barcode':
        return MarketShareService.getMarketShareGraphByBarcode(
          {
            months: params?.months || dateRange(defaultStartDate, defaultEndDate).join(','),
            city: params?.city || regions,
            ...params,
          },
          { signal }
        );
      default:
        return;
    }
  });

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.info({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  useEffect(() => {
    if (data) {
      setLabels(data?.data?.abscissa);
      setTrendsData(data?.data?.ordinate);
    }

    if (error instanceof AxiosError) {
      if (error?.response?.status === 403) {
        openNotification('You do not have permission to perform this action.');
        setTrendsData([]);
      } else {
        openNotification('Data with your request not found');
        setTrendsData([]);
      }
    }

    return () => {
      setTrendsData([]);
    };
  }, [data, error]);

  const chartData = {
    labels,
    datasets: [
      {
        label: '',
        backgroundColor: [
          '#fe001a',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)',
          'rgb(255, 147, 147)',
          'rgb(255, 173, 91)',
          'rgb(205, 214, 125)',
          'rgb(250, 128, 114)',
        ],
        borderColor: [
          '#fe001a',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)',
          'rgb(255, 147, 147)',
          'rgb(255, 173, 91)',
          'rgb(205, 214, 125)',
          'rgb(250, 128, 114)',
        ],
        borderWidth: 1,
        data: trendsData,
      },
    ],
  };

  return (
    <>
      {isFetching ? (
        <Loader />
      ) : trendsData?.length ? (
        <div style={{ margin: '0 auto', height: '455px', width: '90%' }}>
          <Chart type="pie" data={chartData} options={options} />
        </div>
      ) : (
        <Empty />
      )}
    </>
  );
};
