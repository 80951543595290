import { useState } from 'react';
import { Button, Drawer } from 'antd';
import { useMediaQuery } from 'react-responsive';

import { CustomFilter } from '../../components/filter/CustomFilter';
import { ChartPrice } from './components/ChartsPrice';
import { ChartVolume } from './components/ChartVolume';
import { ChartWeek } from './components/ChartsWeek';
import { ChartHour } from './components/ChartsHour';
import styles from './styles.module.scss';
import { isTobacco } from '../../utils/isTobacco';
import { FilterOutlined } from '@ant-design/icons';

export const BuyingBehaviorPage = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [openFilter, setOpenFilter] = useState(false);

  const onOpenFilter = () => {
    setOpenFilter(true);
  };

  const onFilterChange = () => {
    setOpenFilter(false);
  };

  const hide = () => {
    setOpenFilter(false);
  };

  return (
    <div className={styles.company_layout_content}>
      <div className={styles.top_bar}>
        <div className={styles.wrapper}>
          {!isTabletOrMobile && (
            <>
              <div>
                <Drawer title="" placement="left" onClose={hide} open={openFilter}>
                  <CustomFilter onFilterChange={onFilterChange} onCancel={hide} pageName="sales" />
                </Drawer>
                <Button
                  type="default"
                  className={styles.company_filter_btn}
                  onClick={onOpenFilter}
                  icon={<FilterOutlined />}
                >
                  &nbsp;&nbsp;Filter
                </Button>
              </div>
            </>
          )}
        </div>
      </div>

      {isTabletOrMobile && (
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', overflowX: 'auto' }}>
          <div>
            <Drawer title="" placement="left" onClose={hide} open={openFilter}>
              <CustomFilter onFilterChange={onFilterChange} onCancel={hide} pageName="sales" />
            </Drawer>
            <Button
              type="default"
              className={styles.company_filter_btn}
              onClick={onOpenFilter}
              icon={<FilterOutlined />}
            />
          </div>
        </div>
      )}

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: isTabletOrMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          width: '100%',
          marginTop: isTabletOrMobile ? 16 : 0,
        }}
      >
        <ChartPrice isTabletOrMobile={isTabletOrMobile} />
        {!isTobacco() && <ChartVolume isTabletOrMobile={isTabletOrMobile} />}
        <ChartWeek />
        <ChartHour />
      </div>
    </div>
  );
};
