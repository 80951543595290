import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import { CollapseProps, Drawer, notification, PaginationProps, Table, Typography } from 'antd';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import qs from 'qs';
// import type { ResizeCallbackData } from 'react-resizable';

import SalesStatisticsService from '../../../services/salesStatistics';
import { useMobColumns } from './useColumns';
import { useColumns } from './useColumnsNew';
// import { ResizableColumnTitle } from '../../../components/ResizableColumnTitle/ResizableColumnTitle';
import styles from './styles.module.scss';
import { getQueryParams } from '../../../utils/getQueryParams';

const { Text } = Typography;

type Props = {
  pageName: string;
  rule?: any;
  typeOfCompany?: string;
  isExtrapolation?: boolean;
  hiddenColumns?: any;
  isMobile?: boolean;
};

export const SalesStatisticsTable = ({
  pageName,
  rule,
  typeOfCompany,
  isExtrapolation,
  hiddenColumns,
  isMobile,
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  // const queryClient = useQueryClient();
  const [tableData, setTableData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const defaultDate = format(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM')
    .split('-')
    .join('');

  const page = +queryParams!.get('page') || 1;
  const limit = +queryParams!.get('limit') || 10;
  const [totalInfo, setTotalInfo] = useState<Record<string, string | number>>({});
  const regions = rule?.regions?.join(',');
  const onPageChange: PaginationProps['onChange'] = (page, pageSize) => {
    queryParams.set('page', `${page}`);
    queryParams.set('limit', `${pageSize}`);
    navigate({ pathname: location.pathname, search: queryParams.toString() });
  };

  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);

  const defaultStyles: React.CSSProperties = {
    margin: 0,
    padding: 0,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    // fontSize: isMobile ? '11px' : '14px',
  };

  const { data, isFetching, error } = useQuery(
    ['salesStatisticsTable', pageName, isExtrapolation, regions, location.search],
    ({ signal }) => {
      const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
      switch (pageName) {
        case 'company':
          return isExtrapolation
            ? SalesStatisticsService.getExtrapolationSalesStatisticsByCompany(
                getQueryParams(params, defaultDate, regions),
                { signal }
              )
            : SalesStatisticsService.getSalesStatisticsByCompany(getQueryParams(params, defaultDate, regions), {
                signal,
              });

        case 'brand':
          return isExtrapolation
            ? SalesStatisticsService.getExtrapolationSalesStatisticsByBrand(
                getQueryParams(params, defaultDate, regions),
                { signal }
              )
            : SalesStatisticsService.getSalesStatisticsByBrand(getQueryParams(params, defaultDate, regions), {
                signal,
              });

        case 'barcode':
          return isExtrapolation
            ? SalesStatisticsService.getExtrapolationSalesStatisticsByBarcode(
                getQueryParams(params, defaultDate, regions),
                { signal }
              )
            : SalesStatisticsService.getSalesStatisticsByBarcode(getQueryParams(params, defaultDate, regions), {
                signal,
              });
        default:
          return;
      }
    }
  );

  // const getData = useCallback(() => {
  //   queryClient.refetchQueries('trendsTable', { active: true });
  // }, [queryClient]);

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.info({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  useEffect(() => {
    if (data) {
      const dataWithKey = data?.data?.results.map((item, index) => ({
        ...item,
        key: `${item?.local_company + index}`,
      }));

      setTableData(dataWithKey);
      setTotal(data?.data?.count);
      setTotalInfo(data?.data?.additional[0]);
    }
    if (error instanceof AxiosError) {
      if (error?.response?.status === 400) {
        if (error.response?.data[0]) {
          openNotification('cities can`t be extrapolated');
        } else {
          openNotification(error?.response?.data?.['months']?.[0]);
        }
        setTableData([]);
        setTotal(0);
        setTotalInfo({});
      }
      if (error?.response?.status === 403) {
        openNotification('You do not have permission to perform this action.');
        setTableData([]);
        setTotal(0);
        setTotalInfo({});
      } else {
        openNotification('Data with your request not found');
        setTableData([]);
        setTotal(0);
        setTotalInfo({});
      }
    }
  }, [data, error]);

  const showDrawer = useCallback((row: any) => {
    setSelectedRow(row);
    setOpen(true);
  }, []);

  const onCloseDrawer = useCallback(() => {
    setOpen(false);
    setSelectedRow(null);
  }, []);

  const columnsParams = useMemo(
    () => ({
      pageName,
      isExtrapolation,
      hiddenColumns,
      isMobile,
      showDrawer,
    }),
    [pageName, isExtrapolation, hiddenColumns, isMobile, showDrawer]
  );
  const mobileColumnsMemo = useMobColumns(columnsParams);
  const columnsMemo = useColumns(columnsParams);

  // const [columns, setColumns] = useState(columnsMemo);

  // const handleResize: Function =
  //   (index: number) =>
  //   (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
  //     const newColumns = [...columns];
  //     if (size.width >= columns[index].minWidth) {
  //       newColumns[index] = {
  //         ...newColumns[index],
  //         width: size.width,
  //       };
  //       setColumns(newColumns);
  //     }
  //   };

  // const isColumnsChanged = useCallback(() => {
  //   if (columnsParams) {
  //     return true;
  //   }
  // }, [columnsParams]);

  // const memoizedColumns = columns.map((col, index) => ({
  //   ...col,
  //   hidden: col['hidden'],
  //   onHeaderCell: (column) => ({
  //     width: column.width,
  //     onResize: handleResize(index) as React.ReactEventHandler<any>,
  //   }),
  // }));

  // useEffect(() => {
  //   if (isColumnsChanged) {
  //     setColumns(columnsMemo);
  //   }
  // }, [isColumnsChanged]);

  return (
    <>
      <Table
        columns={isMobile ? mobileColumnsMemo : columnsMemo.filter((item) => !item['hidden'])}
        dataSource={tableData}
        loading={isFetching}
        className={styles.ant_table}
        sticky
        rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
        pagination={{
          pageSize: limit,
          defaultPageSize: 10,
          total: total,
          onChange: onPageChange,
          current: page,
          defaultCurrent: 1,
        }}
        scroll={{ x: '100%' }}
        summary={() => {
          return (
            <>
              <Table.Summary.Row>
                {totalInfo?.value && (
                  <>
                    {isMobile ? (
                      <>
                        {totalInfo?.value && (
                          <>
                            <Table.Summary.Cell index={0} key={0} className={styles.align_right} />
                            <Table.Summary.Cell index={1} key={1}>
                              <Text className={styles.total_bold} onClick={() => showDrawer(totalInfo)}>
                                Total{' '}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} key={1} className={styles.align_right}>
                              <Text className={styles.total_bold}>
                                {`${totalInfo?.value}`?.replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}
                              </Text>
                            </Table.Summary.Cell>
                          </>
                        )}
                      </>
                    ) : (
                      columnsMemo
                        .filter((item: any) => !item['hidden'])
                        ?.map((item: any, index: number) => {
                          switch (item.title) {
                            case 'Value kzt':
                              return (
                                <Table.Summary.Cell
                                  index={index}
                                  key={`${item.title + index}`}
                                  className={styles.align_right}
                                >
                                  <Text className={styles.total_bold}>
                                    {`${totalInfo?.value}`?.replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}
                                  </Text>
                                </Table.Summary.Cell>
                              );
                            case 'Items':
                              return (
                                <Table.Summary.Cell
                                  index={index}
                                  key={`${item.title + index}`}
                                  className={styles.align_right}
                                >
                                  <Text className={styles.total_bold}>
                                    {`${totalInfo?.items || ''}`?.replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}
                                  </Text>
                                </Table.Summary.Cell>
                              );
                            case 'Volume':
                              return (
                                <Table.Summary.Cell
                                  index={index}
                                  key={`${item.title + index}`}
                                  className={styles.align_right}
                                >
                                  <Text className={styles.total_bold}>
                                    {`${totalInfo.volume}`?.replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}
                                  </Text>
                                </Table.Summary.Cell>
                              );
                            case 'Avg. price':
                              return (
                                <Table.Summary.Cell
                                  index={index}
                                  key={`${item.title + index}`}
                                  className={styles.align_right}
                                >
                                  <Text className={styles.total_bold}>{totalInfo?.avg_price_per_pcs}</Text>
                                </Table.Summary.Cell>
                              );
                            case 'Avg. price per litre':
                              return (
                                <Table.Summary.Cell
                                  index={index}
                                  key={`${item.title + index}`}
                                  className={styles.align_right}
                                >
                                  <Text className={styles.total_bold}>
                                    <Text className={styles.total_bold}>{totalInfo.avg_price_per_litre}</Text>
                                  </Text>
                                </Table.Summary.Cell>
                              );
                            case 'Stores':
                              return (
                                <Table.Summary.Cell
                                  index={index}
                                  key={`${item.title + index}`}
                                  className={styles.align_right}
                                >
                                  <Text className={styles.total_bold}>
                                    {`${totalInfo.stores ?? '--'}`?.replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}
                                  </Text>
                                </Table.Summary.Cell>
                              );
                            default:
                              return <Table.Summary.Cell index={index} key={`${item.title + index}`} />;
                          }
                        })
                    )}
                  </>
                )}
              </Table.Summary.Row>
            </>
          );
        }}
        // components={{
        //   header: {
        //     cell: ResizableColumnTitle,
        //   },
        // }}
        bordered
        size="middle"
      />
      {selectedRow && (
        <Drawer
          title={
            selectedRow.product_name ? (
              <>
                <p style={defaultStyles}>
                  {selectedRow.product_name} | {selectedRow.barcode}
                </p>
                <p style={{ color: '#A9A9A9', ...defaultStyles }}>
                  {selectedRow.brand} | {selectedRow.local_company}
                </p>
              </>
            ) : selectedRow.brand ? (
              <>
                <p style={defaultStyles}>{selectedRow.brand}</p>
                <p style={{ color: '#A9A9A9', ...defaultStyles }}>{selectedRow.local_company}</p>
              </>
            ) : selectedRow.local_company ? (
              <p style={defaultStyles}>{selectedRow.local_company}</p>
            ) : (
              'Total'
            )
          }
          placement="bottom"
          closable={false}
          onClose={onCloseDrawer}
          open={open}
          key="bottom"
        >
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p>Items</p>
              <div style={{ margin: 0, padding: 0 }}>
                <p style={{ textAlign: 'right', ...defaultStyles }}>Share: {selectedRow.item_per} %</p>
                <p
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    color: '#A9A9A9',
                    ...defaultStyles,
                  }}
                >
                  {`${selectedRow?.items}`?.replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}
                </p>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', color: '#A9A9A9' }}></div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p>Volume</p>
              <div style={{ margin: 0, padding: 0 }}>
                <p style={{ textAlign: 'right', ...defaultStyles }}>Share: {selectedRow.volume_per} %</p>
                <p
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    color: '#A9A9A9',
                    ...defaultStyles,
                  }}
                >
                  <p style={defaultStyles}>{`${selectedRow?.volume}`?.replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}</p>
                </p>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p>Avg. price</p>
              <p style={defaultStyles}>{selectedRow.avg_price_per_pcs}</p>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p>Avg. price per litre</p>
              <p style={defaultStyles}>{selectedRow.avg_price_per_litre}</p>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p>Numeric distribution</p>
              <p style={defaultStyles}>{selectedRow.numeric_dist || '--'}</p>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p>Weighted distribution</p>
              <p style={defaultStyles}>{selectedRow.weight || '--'}</p>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p>CDE</p>
              <p style={defaultStyles}>{selectedRow.cde || '--'}</p>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p>Stores</p>
              <p style={defaultStyles}>{selectedRow.stores}</p>
            </div>
          </div>
        </Drawer>
      )}
    </>
  );
};
