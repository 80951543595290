import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import qs from 'qs';
import { Empty, notification, Spin } from 'antd';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { format } from 'date-fns';

import { dateRange } from '../../../utils/lastThreeMonths';
import BuyingBehaviorService from '../../../services/buyingBehavior';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

type Props = {
  rule?: any;
  isTabletOrMobile?: boolean;
};

export const options = {
  responsive: true,
  radius: 0,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
    },
    title: {
      display: true,
      text: 'Week',
      font: {
        size: 18,
      },
    },
    tooltip: {
      titleFont: {
        size: 14,
      },
      bodyFont: {
        size: 14,
      },
      callbacks: {
        title: (context: any) => {
          return `${context[0]?.raw}`;
        },
        label: (ctx: any) => {
          return `${ctx?.label}`;
        },
      },
    },
  },
  scales: {
    x: {
      display: true,
      title: {
        display: true,
        text: '',
        font: {
          size: 16,
        },
      },
      ticks: {
        font: {
          size: 14,
        },
      },
    },
    y: {
      display: true,
      title: {
        display: true,
        text: 'Items',
        font: {
          size: 16,
        },
      },
      ticks: {
        font: {
          size: 14,
        },
      },
    },
  },
};

export const ChartWeek = ({ rule, isTabletOrMobile }: Props) => {
  const location = useLocation();
  const defaultEndDate = format(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM');
  const defaultStartDate = format(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM');
  const [labels, setLabels] = useState<string[]>(dateRange(defaultStartDate, defaultEndDate));
  const regions = rule?.regions?.join(',');
  const { data, error, isFetching } = useQuery(['week', location.search], ({ signal }) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
    return BuyingBehaviorService.getReportsWeek(
      {
        months: params?.months || dateRange(defaultStartDate, defaultEndDate).join(','),
        city: params?.city || regions,
        local_company: params?.local_company_id,
        subcategory: params?.subcategory_id,
        step: '50',
        ...params,
      },
      { signal }
    );
  });

  // const getData = useCallback(() => {
  //   queryClient.refetchQueries('trends', { active: true });
  // }, [queryClient]);

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.info({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  useEffect(() => {
    if (data) {
      setLabels(data.data['abscissa']);
    }

    if (error instanceof AxiosError) {
      if (error?.response?.status === 403) {
        openNotification('You do not have permission to perform this action.');
      } else {
        openNotification('Data with your request not found');
      }
    }
  }, [data, error]);

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Week',
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)',
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)',
        ],
        borderWidth: 1,
        data: data?.data?.['ordinate'],
      },
    ],
  };

  return (
    <div style={{ width: isTabletOrMobile ? '100%' : '48%' }}>
      {isFetching ? (
        <div style={{ height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Spin />
        </div>
      ) : data?.data?.['ordinate']?.length ? (
        <div style={{ marginTop: '32px' }}>
          <Chart type="bar" data={chartData} options={options} height={120} />
        </div>
      ) : (
        <Empty />
      )}
    </div>
  );
};
