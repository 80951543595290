import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { notification, PaginationProps, Table, Typography } from 'antd';
import { AxiosError } from 'axios';
import type { ColumnsType } from 'antd/es/table';
import { useQuery } from 'react-query';
import qs from 'qs';

import CoExistenceService from '../../../services/coExistance';
import styles from './styles.module.scss';
import { useMediaQuery } from 'react-responsive';
import { getQueryParams } from '../../../utils/getQueryParams';

const { Text } = Typography;
interface DataType {
  key: React.Key;
  name: string;
}

type Props = {};

export const CoExistanceTrendsTable = () => {
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [tableData, setTableData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [totalInfo, setTotalInfo] = useState<any[]>([]);

  const { data, isFetching, error } = useQuery(['coexistanceTrendsTable', location.search, page], ({ signal }) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
    delete params?.page;
    return CoExistenceService.getCoExistanceTrends(getQueryParams(params), { signal });
  });

  const onPageChange: PaginationProps['onChange'] = (page) => {
    setPage(page);
  };

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.info({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  useEffect(() => {
    if (data) {
      setTableData(data?.data?.results);
      setTotal(data?.data?.count);
      setTotalInfo(data?.data?.additional[0]);
    }
    if (error instanceof AxiosError) {
      if (error?.response?.status === 400) {
        openNotification('Fill required fields');
        setTableData([]);
      } else if (error?.response?.status === 403) {
        openNotification('You do not have permission to perform this action.');
        setTableData([]);
      } else {
        openNotification('Data with your request not found');
        setTableData([]);
      }
    }

    return () => {
      setTableData([]);
    };
  }, [data, error, location.search]);

  const defaultStyles: React.CSSProperties = {
    margin: 0,
    padding: 0,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: isTabletOrMobile ? '11px' : '14px',
  };

  const columns = useMemo<ColumnsType<DataType>>(
    () => [
      {
        title: 'Number of Products',
        dataIndex: 'quantity',
        width: isTabletOrMobile ? 90 : 170,
        key: 'quantity',
        align: 'center',
      },
      ...(tableData[0]?.data?.map((item: any, index: number) => {
        const children = [
          {
            title: 'Stores',
            width: isTabletOrMobile ? 100 : 150,
            align: 'right',
            render: (value: any) => {
              return (
                <p style={defaultStyles}>
                  {`${value?.data[index]?.stores}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}
                </p>
              );
            },
          },
          {
            title: 'Share',
            width: isTabletOrMobile ? 100 : 150,
            align: 'right',
            render: (value: any) => {
              return (
                <p style={defaultStyles}>
                  {`${value?.data[index]?.share}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ') + ' %'}
                </p>
              );
            },
          },
        ];
        return {
          title: item?.months,
          children,
          align: 'center',
        };
      }) || []),
    ],
    [tableData, isTabletOrMobile]
  );

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      loading={isFetching}
      bordered
      size="middle"
      scroll={{ x: '100%' }}
      pagination={{
        pageSize: 10,
        defaultPageSize: 10,
        total: total,
        onChange: onPageChange,
        current: page,
        defaultCurrent: 1,
      }}
      summary={() => {
        return (
          <>
            <Table.Summary.Row>
              {totalInfo && (
                <>
                  <Table.Summary.Cell index={0}>
                    <Text className={styles.total_bold} style={{ display: 'flex', justifyContent: 'center' }}>
                      Total
                    </Text>
                  </Table.Summary.Cell>
                  {totalInfo?.['data']?.map((item) => (
                    <>
                      <Table.Summary.Cell index={1} align="right">
                        <span className={styles.summary_cell}>
                          <Text className={styles.total_bold}>
                            {`${item?.stores}`?.replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}
                          </Text>
                        </span>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={2} align="right">
                        <span className={styles.summary_cell}>
                          <Text className={styles.total_bold}>{item?.share ? `${item?.share} %` : '--'}</Text>
                        </span>
                      </Table.Summary.Cell>
                    </>
                  ))}
                </>
              )}
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );
};
