import { Typography } from 'antd';
const { Link, Text } = Typography;

export const useMobColumns = ({ pageName, isExtrapolation, hiddenColumns, isMobile, showDrawer }): any => {
  const defaultStyles: React.CSSProperties = {
    margin: 0,
    padding: 0,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: isMobile ? '11px' : '14px',
  };
  return [
    {
      title: '#',
      key: 'rank_value',
      width: 35,
      minWidth: 30,
      align: 'center',
      render: (value: any) => {
        return (
          <>
            <Text>{value?.rank_value}</Text>
          </>
        );
      },
    },
    {
      title: 'Product name',
      width: isMobile ? 237 : 292,
      minWidth: 237,
      key: 'barcode',
      hidden: pageName === 'company' || pageName === 'brand',
      render: (value: any) => {
        return (
          <Link onClick={() => showDrawer(value)}>
            <p style={defaultStyles}>{value.product_name}</p>
            <p style={{ color: '#A9A9A9', ...defaultStyles }}>
              {value.brand} | {value.local_company}
            </p>
          </Link>
        );
      },
      fixed: isMobile ? undefined : 'left',
    },
    {
      title: 'Brand',
      width: isMobile ? 176 : 192,
      minWidth: 176,
      key: 'brand',
      hidden: pageName !== 'brand',
      render: (value: any) => {
        return (
          <Link onClick={() => showDrawer(value)}>
            <p style={defaultStyles}>{value.brand}</p>
            <p style={{ color: '#A9A9A9', ...defaultStyles }}>{value.local_company}</p>
          </Link>
        );
      },
      fixed: isMobile ? undefined : 'left',
    },
    {
      title: 'Local company',
      width: isMobile ? 170 : 188,
      minWidth: 170,
      maxWidth: 270,
      key: 'local_company',
      hidden: pageName === 'brand' || pageName === 'barcode',
      render: (value: any) => {
        return (
          <Link onClick={() => showDrawer(value)}>
            <p style={defaultStyles}>{value.local_company}</p>
          </Link>
        );
      },
      fixed: isMobile ? undefined : 'left',
    },
    {
      title: 'Value KZT',
      width: isMobile ? 100 : 200,
      minWidth: 158,
      align: 'right',
      hidden: hiddenColumns?.value?.value,
      render: (value: any) => {
        return (
          <div style={{ margin: 0, padding: 0 }}>
            <p style={{ textAlign: 'right', ...defaultStyles }}>Share: {value.value_share} %</p>
            <p
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                color: '#A9A9A9',
                ...defaultStyles,
              }}
            >
              {`${value?.value}`?.replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}
            </p>
          </div>
        );
      },
    },
  ];
};
