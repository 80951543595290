import React from 'react';
import { TrendsTable } from './components/TrendsTable';
import { TrendsChart } from './components/TrendsChart';

type Props = {
  tab: string;
  pageName: string;
  mode: string;
  searchMode?: 'month' | 'day';
};

export const TrendsCategory = ({ tab, pageName, mode = 'table', searchMode }: Props) => {
  const rules = JSON.parse(localStorage.getItem('user'))?.role;
  const TrendsTableMemoized = React.memo(TrendsTable);

  return (
    <>
      {mode === 'table' ? (
        <TrendsTableMemoized tab={tab} pageName={pageName} rule={rules} mode={searchMode} />
      ) : (
        <TrendsChart tab={tab} pageName={pageName} rule={rules} />
      )}
    </>
  );
};
