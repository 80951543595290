import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import qs from 'qs';
import { Empty, notification, Select } from 'antd';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataset,
} from 'chart.js';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { format } from 'date-fns';
import { useMediaQuery } from 'react-responsive';

import TrendsService from '../../../services/trends';
import { backgroundColors } from '../../../utils/randomColor';
import { Loader } from '../../../components/loader/Loader';
import { dateRange } from '../../../utils/lastThreeMonths';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

type Props = {
  tab: string;
  pageName: string;
  rule?: any;
};

export const TrendsChart = ({ tab, pageName, rule }: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const options = {
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        // enabled: false,
        // external: externalTooltipHandler,

        callbacks: {
          title: (context: any) => {
            return `${context[0]?.label}`;
          },
          // label: (ctx: any) => {
          //   const label = `${ctx.dataset.label} - Abs.: ${
          //     data.data.results[ctx.dataset.label]?.absolute[ctx.dataIndex]
          //   }% Rel.: ${data.data.results[ctx.dataset.label]?.relative[ctx.dataIndex]}% Lin.: ${
          //     data.data.results[ctx.dataset.label]?.linear[ctx.dataIndex]
          //   } Share.: ${data.data.results[ctx.dataset.label]?.share[ctx.dataIndex]}% `;
          //   return label;
          // },
          // afterLabel: (ctx: any) => {
          //   // let label = `
          //   // Linear: ${data.data.results[ctx.dataset.label]?.linear[ctx.dataIndex]},
          //   // R: ${data.data.results[ctx.dataset.label]?.relative[ctx.dataIndex]}%,
          //   // A: ${data.data.results[ctx.dataset.label]?.absolute[ctx.dataIndex]}%`;
          //   // return label;
          //   return ' ';
          // },
        },
      },
      title: {
        display: true,
        text: '',
      },
    },
  };
  // const queryClient = useQueryClient();
  const location = useLocation();
  const [trendsData, setTrendsData] = useState<ChartDataset[]>();
  const defaultEndDate = format(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM');
  const defaultStartDate = format(new Date().setMonth(new Date().getMonth() - 6), 'yyyy-MM');
  const [labels, setLabels] = useState<string[]>(dateRange(defaultStartDate, defaultEndDate));
  const chartMode = [
    { label: 'absolute', value: 'absolute' },
    { label: 'relative', value: 'relative' },
    { label: 'linear', value: 'linear' },
    { label: 'share', value: 'share' },
  ];
  const [currentChartMode, setCurrentChartMode] = useState<string>('absolute');
  const regions = rule?.regions?.join(',');

  const { data, error, isFetching } = useQuery(['trends', tab, pageName, location.search], ({ signal }) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
    setLabels(params?.months?.split(',') || dateRange(defaultStartDate, defaultEndDate));
    switch (pageName) {
      case 'company':
        return TrendsService.getTrendsByCompany(
          {
            months: params?.months || dateRange(defaultStartDate, defaultEndDate).join(','),
            measure: tab,
            city: params?.city || regions,
            local_company: params?.local_company_id,
            subcategory: params?.subcategory_id,
            brand: params?.brand_id,
            ...params,
          },
          { signal }
        );

      case 'brand':
        return TrendsService.getTrendsByBrand(
          {
            months: params?.months || dateRange(defaultStartDate, defaultEndDate).join(','),
            measure: tab,
            city: params?.city || regions,
            local_company: params?.local_company_id,
            subcategory: params?.subcategory_id,
            brand: params?.brand_id,
            ...params,
          },
          { signal }
        );

      case 'barcode':
        return TrendsService.getTrendsByBarcode(
          {
            months: params?.months || dateRange(defaultStartDate, defaultEndDate).join(','),
            measure: tab,
            city: params?.city || regions,
            local_company: params?.local_company_id,
            subcategory: params?.subcategory_id,
            brand: params?.brand_id,
            ...params,
          },
          { signal }
        );
      default:
        return;
    }
  });

  // const getData = useCallback(() => {
  //   queryClient.refetchQueries('trends', { active: true });
  // }, [queryClient]);

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.info({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  useEffect(() => {
    if (data) {
      const values =
        Object.keys(data?.data?.results)?.map((item, index) => {
          return {
            label: item,
            data: data.data?.results[item][currentChartMode] as number[],
            borderColor:
              item === 'THE COCA-COLA COMPANY' || item === 'COCA-COLA (THE COCA-COLA COMPANY)'
                ? '#fe001a'
                : backgroundColors[index], // TODO refactor cola colour
            backgroundColor:
              item === 'THE COCA-COLA COMPANY' || item === 'COCA-COLA (THE COCA-COLA COMPANY)'
                ? '#fe001a'
                : backgroundColors[index],
            lineTension: 0.3,
          };
        }) ?? [];
      setTrendsData(values);
    }

    if (error instanceof AxiosError) {
      if (error?.response?.status === 403) {
        openNotification('You do not have permission to perform this action.');
        setTrendsData([]);
      } else {
        openNotification('Data with your request not found');
        setTrendsData([]);
      }
    }

    return () => {
      setTrendsData([]);
    };
  }, [data, currentChartMode, error]);

  const chartData = {
    labels,
    datasets: trendsData as ChartDataset[],
  };

  return (
    <>
      {isFetching ? (
        <Loader />
      ) : trendsData?.length ? (
        <>
          <div style={{ marginTop: '24px' }}>
            <label>Chart type: </label>
            <Select options={chartMode} defaultValue={currentChartMode} onChange={(e) => setCurrentChartMode(e)} />
          </div>
          <div>
            <Chart
              type="line"
              data={chartData}
              options={{ ...options, aspectRatio: isTabletOrMobile ? 1 : undefined }}
              height={100}
            />
          </div>
        </>
      ) : (
        <Empty />
      )}
    </>
  );
};
