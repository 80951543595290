export const downloadFile = async (content: BlobPart, filename?: string) => {
  const fileBlob = new Blob([content], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  if (isMobile && navigator.canShare && navigator.canShare({ files: [new File([fileBlob], filename)] })) {
    try {
      await navigator.share({
        files: [new File([fileBlob], filename, { type: fileBlob.type })],
        title: filename,
      });
      return;
    } catch (error) {
      console.error('Sharing failed', error);
    }
  }

  const link = document.createElement('a');
  link.href = URL.createObjectURL(fileBlob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// export const downloadFile = (content: string, filename?: string) => {
//   const pom = document.createElement('a');
//   const bb = new Blob([content], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
//   pom.setAttribute('href', window.URL.createObjectURL(bb));
//   pom.setAttribute('download', filename || 'test');
//   pom.dataset['downloadurl'] = [
//     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//     pom.download,
//     pom.href,
//   ].join(':');
//   pom.draggable = true;
//   pom.classList.add('dragout');
//   pom.click();
// };
