import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DatePicker, Form, Select } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { format, parse } from 'date-fns';
import qs from 'qs';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import { useQuery } from 'react-query';

import KazakhstanMap from './MapChart';
import { useSubcategories } from '../filter/hook';
import MarketShareService from '../../services/marketShare';
import { Loader } from '../loader/Loader';
import { debounce } from '../../utils/debounce';
import { dateRange } from '../../utils/lastThreeMonths';
import { removeEmptyObject } from '../../utils/removeEmptyObject';
import styles from './styles.module.scss';

const CustomDatePicker = DatePicker.generatePicker<Date>(dateFnsGenerateConfig);
const { RangePicker } = CustomDatePicker;

export const DashboardPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [filtersForm] = Form.useForm();
  const { data: subcategoriesData, isFetching: subcategoriesIsFetching } = useSubcategories();
  const currentMonth = format(new Date().setMonth(new Date().getMonth() - 1), 'yyyyMM');
  const isLaptop = useMediaQuery({ query: '(min-width: 1024px) and (max-width: 1600px)' });
  const [total, setTotal] = useState<number | string>('');
  const [tableData, setTableData] = useState<any[]>([]);
  const [maxShare, setMaxShare] = useState(0);
  const [minShare, setMinShare] = useState(0);

  const valuesFromUrl = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as Record<string, string>;
  const startMonth = valuesFromUrl?.months?.split(',')[0] || null;
  const endMonth = valuesFromUrl?.months?.split(',')[valuesFromUrl?.months?.split(',').length - 1] || null;
  const defaultEndDate = format(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM');
  const defaultStartDate = format(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM');
  const parseYearMonth = (yearMonth: string) => parse(yearMonth, 'yyyyMM', new Date());

  const initialValues = {
    monthsRange: valuesFromUrl?.months
      ? [parseYearMonth(startMonth), parseYearMonth(endMonth)]
      : [parse(currentMonth, 'yyyyMM', new Date()), parse(currentMonth, 'yyyyMM', new Date())],
    subcategory: valuesFromUrl?.subcategory ? valuesFromUrl?.subcategory?.split(',').map(Number) : [],
  };

  const { data, isFetching } = useQuery(['dashboard', location.search], ({ signal }) => {
    const params = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }) as Record<string, string>;

    return MarketShareService.getShareByRegion(
      {
        months: params?.months || dateRange(defaultStartDate, defaultEndDate).join(','),
        ...params,
      },
      { signal }
    );
  });

  const onSearch = (values: any) => {
    let normalized = {} as any;
    if (values.monthsRange) {
      const months = dateRange(format(values?.monthsRange[0], 'yyyy-MM'), format(values?.monthsRange[1], 'yyyy-MM'));
      delete values.monthsRange;
      values = {
        ...values,
        months: months,
      };
    }
    Object.keys(values).forEach((key) => {
      if (values[key] != null) {
        if (Array.isArray(values[key])) {
          normalized[key] = values[key].join(',');
        } else {
          normalized[key] = values[key];
        }
      }
    });
    navigate({
      pathname: location.pathname,
      search: `${qs.stringify(removeEmptyObject(normalized))}`,
    });
  };

  const debouncedOnSearch = debounce(onSearch, 1000);

  useEffect(() => {
    if (data) {
      setTableData(data?.data?.results);
      setTotal(data?.data?.additional[0]?.share);
      const valueShares = data?.data?.results.map((item) => parseFloat(item.share));
      setMaxShare(Math.max(...valueShares));
      setMinShare(Math.min(...valueShares));
    }
  }, [data]);

  useEffect(() => {
    const params = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }) as Record<string, string>;
    if (Object.keys(params).length === 0) {
      filtersForm.resetFields(null);
    }
  }, [location.search, filtersForm]);

  return (
    <div>
      <Form
        form={filtersForm}
        layout="inline"
        onValuesChange={(_, allValues) => debouncedOnSearch(allValues)}
        initialValues={initialValues}
        style={{ padding: 40 }}
      >
        <div className="user-filter-container" style={{ alignItems: 'center' }}>
          <Form.Item name="monthsRange" noStyle>
            <RangePicker
              format="YYYY-MM"
              picker="month"
              allowClear={true}
              style={{ width: '100%', height: '32px' }}
              className={styles.ant_calendar}
            />
          </Form.Item>

          <Form.Item name="subcategory_id">
            <Select
              options={subcategoriesData}
              allowClear={true}
              loading={subcategoriesIsFetching}
              placeholder="subcategory"
              style={{ width: '156px', marginLeft: 8 }}
            />
          </Form.Item>
        </div>
        <div style={{ marginLeft: isLaptop ? '100px' : '250px', marginTop: 0 }}>
          {/* <strong style={{ fontSize: '30px' }}>
            Kazakhstan share: <code>{total}%</code>
          </strong> */}
          {total ? (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
              <div style={{ marginTop: '10px', marginBottom: '5px', width: '500px', position: 'relative' }}>
                <div
                  style={{
                    flex: 1,
                    height: '20px',
                    background: 'linear-gradient(to right, #B0C4DE, #5A79C1, #0D1B6A)',
                    borderRadius: '5px',
                    position: 'relative',
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      left: '50%',
                      top: '0',
                      width: '2px',
                      height: '20px',
                      background: 'white',
                      transform: 'translateX(-50%)',
                    }}
                  ></div>

                  <div
                    style={{
                      position: 'absolute',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      top: '-20px',
                      color: '#000',
                      fontSize: '14px',
                      fontFamily: 'Roboto Condensed Regular',
                    }}
                  >
                    Kazakhstan share: <code>{total}%</code>
                  </div>

                  <div
                    style={{
                      position: 'absolute',
                      left: '0%',
                      top: '1%',
                      color: 'white',
                      fontSize: '14px',
                      marginLeft: 4,
                      fontFamily: 'Roboto Condensed Regular',
                    }}
                  >
                    Min ({minShare})
                  </div>

                  <div
                    style={{
                      position: 'absolute',
                      right: '0%',
                      top: '1%',
                      color: 'white',
                      fontSize: '14px',
                      marginRight: 4,
                      fontFamily: 'Roboto Condensed Regular',
                    }}
                  >
                    Max ({maxShare})
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </Form>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '-33px',
          height: '100%',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        {isFetching ? <Loader /> : <KazakhstanMap data={tableData || []} total={total || null} />}
      </div>
    </div>
  );
};
