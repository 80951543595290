import { useMemo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Button, Drawer, Tabs } from 'antd';
import { FilterOutlined, InfoCircleOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import qs from 'qs';
import { useMediaQuery } from 'react-responsive';

import { CustomFilter } from '../../components/filter/CustomFilter';
import { AbcAnalysisTable } from './components/Table';
import AbcAnalysisService from '../../services/abcAnalysis';
// import { downloadFile } from '../../utils/downloadFile';
import { isTobacco } from '../../utils/isTobacco';
import { InformationModal } from './components/InformationModal';
import { DownloadsDrawer } from '../../components/DownloadsDrawer/DownloadsDrawer';
import { openNotification } from '../../utils/lastThreeMonths';
import styles from './styles.module.scss';
import { getMeasureQueryParams } from '../../utils/getQueryParams';

export const AbcAnalysisPage = () => {
  const { tab } = useParams<{ tab: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [currentTab, setCurrentTab] = useState<string>(tab || 'value');
  const [openFilter, setOpenFilter] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [isOpenDownloads, setIsOpenDonwloads] = useState<boolean>(false);
  const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;

  const onOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const onFilterChange = () => {
    setOpenFilter(false);
  };

  const hide = () => {
    setOpenFilter(false);
  };

  const closeDownloads = () => {
    setIsOpenDonwloads(false);
  };

  const tabs = useMemo(
    () => [
      { label: 'Value', key: 'value', children: <AbcAnalysisTable tab={currentTab} /> },
      { label: 'Item', key: 'item', children: <AbcAnalysisTable tab={currentTab} /> },
      !isTobacco() && {
        label: 'Volume',
        key: 'volume',
        children: <AbcAnalysisTable tab={currentTab} />,
      },
    ],
    [currentTab]
  );

  const onChange = (key: string) => {
    setCurrentTab(key);
    navigate({
      pathname: `/abc-analysis/${key}`,
      search: location.search,
    });
  };

  const handleDownloadFile = () => {
    setExportLoading(true);
    AbcAnalysisService.exportByType(getMeasureQueryParams(tab, params))
      .then((res) => {
        setIsOpenDonwloads(true);
        openNotification('File added to downloads folder', 'success');
        // downloadFile(res.data, `abc_analysis_report_${params?.months}` || 'abc_analysis_report_');
      })
      .finally(() => setExportLoading(false));
  };

  return (
    <>
      <div className={styles.company_layout_content}>
        <div className={styles.top_bar}>
          <div className={styles.wrapper}>
            {isTabletOrMobile && (
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', overflowX: 'auto' }}>
                <div>
                  <Drawer title="" placement="left" onClose={hide} open={openFilter}>
                    <CustomFilter
                      onFilterChange={onFilterChange}
                      onCancel={hide}
                      pageName="abc-analysis"
                      disabledMode
                    />
                  </Drawer>
                  <Button
                    type="default"
                    className={styles.company_filter_btn}
                    onClick={onOpenFilter}
                    icon={<FilterOutlined />}
                  />
                </div>
              </div>
            )}
            {!isTabletOrMobile && (
              <div>
                <Drawer title="" placement="left" onClose={hide} open={openFilter}>
                  <CustomFilter onFilterChange={onFilterChange} onCancel={hide} pageName="abc-analysis" disabledMode />
                </Drawer>
                <Button
                  type="default"
                  className={styles.company_filter_btn}
                  onClick={onOpenFilter}
                  icon={<FilterOutlined />}
                >
                  &nbsp;&nbsp;Filter
                </Button>
              </div>
            )}
          </div>
          <Button
            size="large"
            shape="circle"
            type="link"
            icon={<InfoCircleOutlined />}
            onClick={() => setOpenModal(true)}
          />
          <Button type="default" className={styles.export_btn} onClick={handleDownloadFile} loading={exportLoading}>
            <VerticalAlignBottomOutlined />
            Export
          </Button>
        </div>
        <Tabs defaultActiveKey={currentTab} onChange={onChange} items={tabs} />
        <InformationModal open={openModal} close={handleCloseModal} />
      </div>
      <Drawer title="Downloads" width="auto" placement="right" onClose={closeDownloads} open={isOpenDownloads}>
        <DownloadsDrawer open={isOpenDownloads} />
      </Drawer>
    </>
  );
};
